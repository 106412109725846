import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ExternalConfig from '../../common/ExternalConfig';

/**
 * Footer of dashboard form
 */
export function Footer(): JSX.Element {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.payperchain.com/">
        PayperChain GmbH
      </Link>{' '}
      {new Date().getFullYear()}
      {' – Platform version: '}
      {ExternalConfig.REACT_APP_PLATFORM_VERSION}
    </Typography>
  );
}
