import React from 'react';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import MaterialTable, { Column } from 'material-table';
import { CircularProgress, IconButton, Paper } from '@material-ui/core';

import { useConfig } from '../../../../common/config';
import { InsuranceInvoiceDto } from '../../../../generated';
import { InsuranceInvoiceDetails } from './InsuranceInvoiceDetails';
import InsuranceInvoiceStatusChip from './InsuranceInvoiceStatusChip';
import { MaterialTableIcons } from '../../../assets/MaterialTableIcons';
import { dateComparer, formatDateTime } from '../../../../common/format';
import { NumberFormatEurText } from '../../../assets/numberformat/NumberFormatEur';

export function InsuranceInvoicesTable(props: {
  insurancesData: InsuranceInvoiceDto[];
  promiseInProgress: boolean;
  setEditInsuranceInvoiceDialog: (open: { open: boolean; insuranceInvoice: InsuranceInvoiceDto }) => void;
}): JSX.Element {
  const { insurancePage } = useConfig();

  // Trnaslations
  const { t } = useTranslation();
  const rowTxt = t('row');
  const editTxt = t('edit');
  const containsTxt = t('contains');
  const selectDateTxt = t('selectDate');
  const largerThanTxt = t('largerThan');
  const ppuAmountTxt = t('insurance.ppuAmount');
  const periodEndTxt = t('insurance.periodEnd');
  const baseAmountTxt = t('insurance.baseAmount');
  const periodStartTxt = t('insurance.periodStart');
  const invoiceAmountTxt = t('insurance.invoiceAmount');
  const noRecordsToDisplayTxt = t('noRecordsToDisplay');

  const ColumnPeriodStart: Column<InsuranceInvoiceDto> = {
    title: periodStartTxt,
    field: 'invoiceDetails.periodStart',
    type: 'date',
    render: (data: InsuranceInvoiceDto) => formatDateTime(data.invoiceDetails.periodStart),
    customFilterAndSearch: (term: string, data: InsuranceInvoiceDto) =>
      dateComparer(term, data.invoiceDetails.periodStart),
    filterPlaceholder: selectDateTxt,
  };

  const ColumnPeriodEnd: Column<InsuranceInvoiceDto> = {
    title: periodEndTxt,
    field: 'invoiceDetails.periodEnd',
    type: 'date',
    render: (data: InsuranceInvoiceDto) => formatDateTime(data.invoiceDetails.periodEnd),
    customFilterAndSearch: (term: string, data: InsuranceInvoiceDto) =>
      dateComparer(term, data.invoiceDetails.periodEnd),
    filterPlaceholder: selectDateTxt,
  };

  const ColumnBaseAmount: Column<InsuranceInvoiceDto> = {
    title: baseAmountTxt,
    field: 'invoiceDetails.baseAmount',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: InsuranceInvoiceDto) => <NumberFormatEurText value={data.invoiceDetails.baseAmount} />,
    customFilterAndSearch: (term: number, rowData: InsuranceInvoiceDto) => {
      return rowData.invoiceDetails.baseAmount ? term <= rowData.invoiceDetails.baseAmount : false;
    },
    filterPlaceholder: largerThanTxt,
  };

  const ColumnPayPerUseAmount: Column<InsuranceInvoiceDto> = {
    title: ppuAmountTxt,
    field: 'invoiceDetails.payPerUseAmount',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: InsuranceInvoiceDto) => <NumberFormatEurText value={data.invoiceDetails.payPerUseAmount} />,
    customFilterAndSearch: (term: number, rowData: InsuranceInvoiceDto) => {
      return rowData.invoiceDetails.payPerUseAmount ? term <= rowData.invoiceDetails.payPerUseAmount : false;
    },
    filterPlaceholder: largerThanTxt,
  };

  const ColumnInvoiceAmount: Column<InsuranceInvoiceDto> = {
    title: invoiceAmountTxt,
    field: 'invoiceDetails.invoiceAmount',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: InsuranceInvoiceDto) => <NumberFormatEurText value={data.invoiceDetails.invoiceAmount} />,
    customFilterAndSearch: (term: number, rowData: InsuranceInvoiceDto) => {
      return rowData.invoiceDetails.invoiceAmount ? term <= rowData.invoiceDetails.invoiceAmount : false;
    },
    filterPlaceholder: largerThanTxt,
  };

  const ColumnStatus: Column<InsuranceInvoiceDto> = {
    title: 'Status',
    field: 'insuranceStatus',
    filterPlaceholder: containsTxt,
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: (data: InsuranceInvoiceDto) => (
      <InsuranceInvoiceStatusChip insuranceInvoiceStatus={data.invoiceStatus} size="small" />
    ),
  };

  const ColumnEdit: Column<InsuranceInvoiceDto> = {
    title: editTxt,
    sorting: false,
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: (data: InsuranceInvoiceDto) => (
      <IconButton
        onClick={() => {
          props.setEditInsuranceInvoiceDialog({ open: true, insuranceInvoice: data });
        }}
      >
        <Edit />
      </IconButton>
    ),
  };

  const columnDefs: Column<InsuranceInvoiceDto>[] = [
    ColumnPeriodStart,
    ColumnPeriodEnd,
    ColumnInvoiceAmount,
    ColumnBaseAmount,
    ColumnPayPerUseAmount,
    ColumnStatus,
  ];

  // Add edit button
  if (insurancePage.canEditInsurances) {
    columnDefs.push(ColumnEdit);
  }

  return (
    <MaterialTable
      localization={{
        pagination: {
          labelRowsSelect: rowTxt,
        },
        body: {
          emptyDataSourceMessage: props.promiseInProgress ? <CircularProgress /> : noRecordsToDisplayTxt,
        },
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      icons={MaterialTableIcons()}
      columns={columnDefs}
      data={props.insurancesData}
      detailPanel={(row) => {
        return <InsuranceInvoiceDetails insuranceInvoice={row} />;
      }}
      options={{
        filtering: true,
        sorting: true,
        showTitle: false,
        search: false,
      }}
    />
  );
}
