// the translations
// manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const resources = {
  en: {
    translation: {
      // Shared arcoss different components
      type: 'Type',
      none: 'None',
      units: 'Units',
      unit: 'Unit',
      amount: 'Amount',
      lastUpdate: 'Last Update',
      edit: 'Edit',
      add: 'Add new',
      deleteBtn: 'Delete',
      closeBtn: 'Close',
      saveBtn: 'Save',
      cancel: 'Cancel',
      canceled: 'Canceled',
      ok: 'Ok',
      row: 'rows',
      search: 'Search',
      created: 'Created',
      updated: 'Updated',
      reload: 'Reload',
      machine: 'Machine',
      wallet: 'Wallet',
      machines: 'Machines',
      about: 'About',
      network: 'Network',
      members: 'Members',
      machineId: 'Machine ID',
      payments: 'Payments',
      paymentId: 'Payment ID',
      timestamp: 'Timestamp',
      timestamps: 'Timestamps',
      machineOwner: 'Machine Owner',
      machineUser: 'Machine User',
      machineName: 'Machine Name',
      pricePerUnit: 'Price per unit',
      paymentMethod: 'Payment Method',
      paymentThreshold: 'Payment Threshold',
      timeBasedTrigger: 'Time-based Trigger',
      tresholdTrigger: 'Treshold Trigger',
      paymentProvider: 'Payment Provider',
      bankAccounts: 'Bank Accounts',
      machineObserver: 'Machine Observer',
      machineObservers: 'Machine Observers',
      insurer: 'Insurer',
      insurers: 'Insurers',
      roles: 'Roles',
      active: 'Active',
      inactive: 'Inactive',
      paid: 'Paid',
      unpaid: 'Unpaid',
      billed: 'Billed',
      pending: 'Pending',
      suspended: 'Suspended',
      selectDate: 'select date',
      largerThan: 'larger than',
      contains: 'contains',
      machineDetails: 'Machine Details',
      noRecordsToDisplay: 'No records to display',
      fieldNegative: "Field can't be negative",
      includedMachineActivities: 'Included Machine Activities',
      errorFetchingData: 'There was an error fetching the data!',
      userUnauthorized: 'User Unauthorized!',

      // Generics
      required: '{{field}} is required',
      exceedChar: '{{field}} may not exceed 128 characters',

      // Generics related fields
      fields: {
        displayName: 'Dispaly Name',
        legalName: 'Legal Name',
        insuranceId: 'Insurance ID ',
        field: 'Field',
        machineName: 'Machine Name',
      },

      // extraPaymentInformationDialog and paymentInformationDialog shares this entry
      extraPaymentInformationDialog: {
        duplicatedIBAN: 'This IBAN was already used for another account',
        deletedSuccessMsg: 'deleted successfully',
        editedSuccessMsg: 'edited successfully',
        updatedSuccessMsg: 'updated successfully',
        addedSuccessMsg: 'Payment information added successfully',
        updatePaymentError: 'Error while updating payment information',
        updatePaymentStatusError: 'Error while updating payment information with status',
        ibanInvalid: 'Invalid IBAN',
        bicInvalid: 'Invalid BIC',
        managePayment: 'Manage Payment Information',
        editSepaInfo: 'You can edit your SEPA payment information here.',
      },

      paymentDropDown: {
        title: 'All Accounts',
        addAccountHover: 'Add New Account',
        editAccountHover: 'Click to edit or delete',
        defalut: 'Default',
      },

      paymentInfoDeleteEditDialog: {
        warning: 'Warning',
        descriptionDelete:
          'The bank account you are attempting to delete may be currently assigned as a payment method for a machine. Please ensure to assign a new account to the machine after deleting.',
        descriptionEdit:
          'The bank account you are attempting to edit may be currently assigned as a payment method for a machine. Please ensure to assign a new account to the machine after editing.',
      },

      userMenu: {
        profile: 'Profile:',
        user: 'User:',
      },

      insurance: {
        insurance: 'insurance',
        insurances: 'Insurances',
        newInsurance: 'New Insurance',
        insuranceNumber: 'Insurance number',
        counterParty: 'Counterparty',
        invoiceDate: 'Invoice date',
        paymentDate: 'Payment date',
        periodStart: 'Period start',
        periodEnd: 'Period end',
        baseAmount: 'Base amount',
        invoiceAmount: 'Invoice amount',
        ppuAmount: 'Pay per use amount',
        invoiceDetails: 'Invoice Details',
        billedActivityUnits: 'Billed activity units',
        recordedActivityUnits: 'Recorded activity units',
      },

      insuranceDetailsDialog: {
        editInsuranceDetails: 'Edit insurance details',
        startTimestamp: 'Start timestamp',
        endTimestamp: 'End timestamp',
        insuredAmount: 'Insured amount',
        excessAmount: 'Excess amount',
        seperateInsuredItems: 'Separate insured risk items by semicolon',
        insuredRisk: 'Insured risks',
      },

      // 1
      insuranceDetails: {
        insuranceDetails: 'Insurance Details',
        paymentDetails: 'Payment Details',
        ppuDetails: 'Pay per Use Details',
      },

      insuranceInvoiceDialog: {
        InsuranceInvoiceStatusEnumError: 'Value does not match InsuranceInvoiceStatusEnum',
        insuranceInvoice: 'Insurance Invoice',
        insuranceInvoiceID: 'Insurance Invoice ID',
      },

      insuranceInvoices: {
        insuranceInvoices: 'Insurance Invoices',
        newInvoice: 'New Invoice',
        totalInvoiceAmount: 'Total invoice amount:',
      },

      machineDetailsDialog: {
        insuranceDetails: 'Edit insurance machine details',
        manufacturer: 'Manufacturer',
        serialNumber: 'Serial number',
        manufacturingDate: 'Manufacturing date',
        location: 'Location',
      },

      paymentDetailsDialog: {
        editInsuranceDetails: 'Edit insurance payment details',
        method: 'Method',
        interval: 'Interval',
      },

      payPerUseDetailsDialog: {
        editInsurancePPUDetails: 'Edit insurance pay per use details',
        annualExpectedAmount: 'Annual expected amount',
        annualBaseAmount: 'Annual base amount',
        annualCapAmount: 'Annual cap amount',
        annualExpectedActivity: 'Annual expected activity',
      },

      paymentsTable: {
        initiated: 'Initiated',
        pending: 'Pending',
        confirmed: 'Confirmed',
      },

      MachineDetails: {
        downloadSuccess: 'Download successful',
        downloadFailed: 'Failed to download activities',
        machineActivities: 'Machine Activities',
      },

      machineDialog: {
        editMachine: 'Edit Machine',
        addMachine: 'Add new Machine',
        machineNameRequired: 'Machine Name is required',
        ppcPositiveNumber: 'Price per Unit has to be a positive number',
        thresholdPositiveNumber: 'Payment Threshold has to be a positive number',
        oneDayNeedSelected: 'At least one day needs to be selected',
        nomoreTwoDaysSelected: 'No more than two days can be selected',
        daysOfMonthRequired: 'Days of Month is required',
        extraSettings: 'Extra Settings',
        selectOnlyTwoDays: 'Select up to two days',
        endOfMonth: 'End of month',
        success: {
          create: 'Machine created successfully',
          delete: 'Machine deleted successfully',
          update: 'Machine updated successfully',
        },
        error: {
          create: 'Error while creating the machine with status "{{status}}": {{message}}',
          update: 'Error while updating the machine with status "{{status}}": {{message}}',
          delete: 'Error while deleting the machine with status "{{status}}": {{message}}',
        },
      },

      machinesPage: {
        newMachine: 'New Machine',
      },

      machineActivityTable: {
        activityTimestamp: 'Activity Timestamp',
        activityUnits: 'Activity Units',
        activityCost: 'Activity Cost',
        activityStatus: 'Activity Status',
        activityNote: 'Activity Note',
      },

      memberDialog: {
        editMember: 'Edit member',
        onboardMember: 'Onboard a new member here and set its roles in the PayperChain business network.',
        currentlyEditing: 'Currently editing:',
        memberUpdateSuccess: 'Member status updated successfully',
        updateMemberError: 'Error while updating member status',
        updateMemberErrorStatus: 'Error while updating member status with status',
        suspend: 'Suspend',
        activate: 'Activate',
        currentStatus: 'Current status: ',
        memberRolesUpdatedSuccess: 'Member roles updated successfully',
        updateMemberRolesError: 'Error while updating member roles',
        updateMemberRolesErrorStatus: 'Error while updating member roles with status',
        updateRoles: 'Update Roles',
      },

      membersTable: {
        identity: 'Identity',
        membershipRequestedSuccess: 'Membership successfully requested',
        membershipRequestedError: 'Error requesting membership',
        membershipRequestedErrorStatus: 'Error requesting membership with status',
        networkMembership: 'You are not part of the PayperChain business network',
        requestMembership: 'Request Membership',
        networkCreateSuccess: 'Business network successfully created',
        networkCreateError: 'Error creating business network',
        networkCreateErrorStatus: 'Error creating business network with status',
        networkExistance: 'The PayperChain business network does not yet exist',
        createNetwork: 'Create PayperChain business network',
      },

      aboutPage: {
        title: 'About Us',
        security: 'We make Pay Per Use secure.',
        flexibility: 'Using the latest blockchain technology, we provide automated payment flows.',
        investment:
          'Invest - regardless of your current order situation. Bring more flexibility to your cash flow. Increase your long term revenue.',
        contactUs: 'Contact us!',
      },

      networkPage: {
        activeNode: 'Active Node',
        notary: 'Notary',
        otherNodes: 'Other Nodes:',
        networkInfo: 'Network Information',
        organization: 'Organization',
        locality: 'Locality',
        country: 'Country',
      },

      walletPage: {
        summary: 'Summary',
        paymentCount: 'Payment Count',
        TotalAmountInitiated: 'Total Amount Initiated',
        TotalAmountPending: 'Total Amount Pending',
        TotalAmountConfirmed: 'Total Amount Confirmed',
      },

      errorPage: {
        error: 'Error',
        pageNotFound: 'The page you are looking for could not be found.',
        click: 'Click',
        here: 'here',
        accessPage: 'to access the start page.',
      },
    },
  },

  de: {
    translation: {
      // Shared across different components
      type: 'Typ',
      none: 'Keine',
      units: 'Einheiten',
      unit: 'Einheit',
      amount: 'Betrag',
      lastUpdate: 'Letzte Aktualisierung',
      edit: 'Bearbeiten',
      add: 'hinzufügen',
      deleteBtn: 'Löschen',
      closeBtn: 'Schließen',
      saveBtn: 'Speichern',
      cancel: 'Abbrechen',
      canceled: 'Abgesagt',
      ok: 'Ok',
      row: 'Zeilen',
      search: 'Suchen',
      created: 'Erstellt',
      updated: 'Aktualisiert',
      reload: 'aktualisieren',
      machine: 'Maschine',
      wallet: 'Wallet',
      machines: 'Maschinen',
      about: 'Über uns',
      network: 'Netzwerk',
      members: 'Teilnehmer',
      machineId: 'Maschinen-ID',
      payments: 'Zahlungen',
      paymentId: 'Zahlungs-ID',
      timestamp: 'Zeitstempel',
      timestamps: 'Zeitstempel',
      machineOwner: 'Maschinenbesitzer',
      machineUser: 'Maschinennutzer',
      machineName: 'Maschinenname',
      pricePerUnit: 'Preis pro Einheit',
      paymentMethod: 'Zahlungsmethode',
      paymentThreshold: 'Zahlungsschwelle',
      timeBasedTrigger: 'Zeitbasierter Trigger',
      tresholdTrigger: 'Schwellenwert-Trigger',
      paymentProvider: 'Zahlungsanbieter',
      bankAccounts: 'Bankkonten',
      machineObserver: 'Maschinenbeobachter',
      machineObservers: 'Maschinenbeobachter',
      insurer: 'Versicherer',
      insurers: 'Versicherer',
      roles: 'Rollen',
      active: 'Aktiv',
      inactive: 'Inaktiv',
      paid: 'Bezahlt',
      unpaid: 'Unbezahlt',
      billed: 'Abgerechnet',
      pending: 'Ausstehend',
      suspended: 'Gesperrt',
      selectDate: 'Datum auswählen',
      largerThan: 'größer als',
      contains: 'enthält',
      machineDetails: 'Maschinendetails',
      noRecordsToDisplay: 'keine Daten vorhanden',
      fieldNegative: 'Feld darf nicht negativ sein',
      includedMachineActivities: 'Eingeschlossene Maschinenaktivitäten',
      errorFetchingData: 'Beim Abrufen der Daten ist ein Fehler aufgetreten!',
      userUnauthorized: 'Benutzer nicht autorisiert!',

      // Generics
      required: '{{field}} ist erforderlich',
      exceedChar: '{{field}} darf 128 Zeichen nicht überschreiten',

      // Generics related fields
      fields: {
        displayName: 'Anzeigename',
        legalName: 'Rechtlicher Name',
        insuranceId: 'Versicherungs-ID',
        field: 'Feld',
        machineName: 'Maschinenname',
      },
      // extraPaymentInformationDialog and paymentInformationDialog shares this entry
      extraPaymentInformationDialog: {
        duplicatedIBAN: 'Diese IBAN wurde bereits für ein anderes Konto verwendet',
        deletedSuccessMsg: 'Erfolgreich gelöscht',
        editedSuccessMsg: 'Erfolgreich bearbeitet',
        updatedSuccessMsg: 'Erfolgreich aktualisiert',
        addedSuccessMsg: 'Zahlungsinformationen erfolgreich hinzugefügt',
        updatePaymentError: 'Fehler beim Aktualisieren der Zahlungsinformationen',
        updatePaymentStatusError: 'Fehler beim Aktualisieren der Zahlungsinformationen mit Status',
        ibanInvalid: 'Ungültige IBAN',
        bicInvalid: 'Ungültige BIC',
        managePayment: 'Zahlungsinformationen verwalten',
        editSepaInfo: 'Hier können Sie Ihre SEPA-Zahlungsinformationen bearbeiten.',
      },

      paymentDropDown: {
        title: 'Alle Konten',
        addAccountHover: 'Neues Konto hinzufügen',
        editAccountHover: 'Klicken, um zu bearbeiten oder zu löschen',
        default: 'Standard',
      },

      paymentInfoDeleteEditDialog: {
        warning: 'Warnung',
        descriptionDelete:
          'Das Bankkonto, das Sie löschen möchten, ist möglicherweise derzeit als Zahlungsmethode für eine Maschine zugewiesen. Stellen Sie sicher, dass Sie der Maschine nach dem Löschen ein neues Konto zuweisen.',
        descriptionEdit:
          'Das Bankkonto, das Sie bearbeiten möchten, ist möglicherweise derzeit als Zahlungsmethode für eine Maschine zugewiesen. Stellen Sie sicher, dass Sie der Maschine nach dem Bearbeiten ein neues Konto zuweisen.',
      },

      userMenu: {
        profile: 'Profil: ',
        user: 'Benutzer: ',
      },

      insurance: {
        insurance: 'Versicherung',
        insurances: 'Versicherungen',
        newInsurance: 'Neue Versicherung',
        insuranceNumber: 'Versicherungsnummer',
        counterParty: 'Gegenpartei',
        invoiceDate: 'Rechnungsdatum',
        paymentDate: 'Zahlungsdatum',
        periodStart: 'Zeitraum Start',
        periodEnd: 'Zeitraum Ende',
        baseAmount: 'Grundbetrag',
        invoiceAmount: 'Rechnungsbetrag',
        ppuAmount: 'Pay-per-Use Betrag',
        invoiceDetails: 'Rechnungsdetails',
        billedActivityUnits: 'Abgerechnete Aktivitätseinheiten',
        recordedActivityUnits: 'Erfasste Aktivitätseinheiten',
      },

      insuranceDetailsDialog: {
        editInsuranceDetails: 'Versicherungsdetails bearbeiten',
        startTimestamp: 'Startzeitstempel',
        endTimestamp: 'Endzeitstempel',
        insuredAmount: 'Versicherte Summe',
        excessAmount: 'Selbstbeteiligung',
        seperateInsuredItems: 'Versicherte Risiken durch Semikolon trennen',
        insuredRisk: 'Versicherte Risiken',
      },

      insuranceDetails: {
        insuranceDetails: 'Versicherungsdetails',
        paymentDetails: 'Zahlungsdetails',
        ppuDetails: 'Pay-per-Use-Details',
      },

      insuranceInvoiceDialog: {
        InsuranceInvoiceStatusEnumError: 'Wert stimmt nicht mit InsuranceInvoiceStatusEnum überein',
        insuranceInvoice: 'Versicherungsrechnung',
        insuranceInvoiceID: 'Versicherungsrechnungs-ID',
      },

      insuranceInvoices: {
        insuranceInvoices: 'Versicherungsrechnungen',
        newInvoice: 'Neue Rechnung',
        totalInvoiceAmount: 'Gesamtrechnungsbetrag:',
      },

      machineDetailsDialog: {
        insuranceDetails: 'Versicherungsmaschinendetails bearbeiten',
        manufacturer: 'Hersteller',
        serialNumber: 'Seriennummer',
        manufacturingDate: 'Herstellungsdatum',
        location: 'Standort',
      },

      paymentDetailsDialog: {
        editInsuranceDetails: 'Versicherung Zahlungsdetails bearbeiten',
        method: 'Methode',
        interval: 'Intervall',
      },

      payPerUseDetailsDialog: {
        editInsurancePPUDetails: 'Versicherung Pay-per-Use-Details bearbeiten',
        annualExpectedAmount: 'Erwarteter Jahresbetrag',
        annualBaseAmount: 'Jahresbasisbetrag',
        annualCapAmount: 'Jahreshöchstbetrag',
        annualExpectedActivity: 'Erwartete Jahresaktivität',
      },

      paymentsTable: {
        initiated: 'Initiiert',
        pending: 'Ausstehend',
        confirmed: 'Bestätigt',
      },

      MachineDetails: {
        downloadSuccess: 'Download erfolgreich',
        downloadFailed: 'Aktivitäten konnten nicht heruntergeladen werden',
        machineActivities: 'Maschinenaktivitäten',
      },

      machineDialog: {
        editMachine: 'Maschine bearbeiten',
        addMachine: 'Neue Maschine hinzufügen',
        machineNameRequired: 'Maschinenname ist erforderlich',
        ppcPositiveNumber: 'Preis pro Einheit muss eine positive Zahl sein',
        thresholdPositiveNumber: 'Zahlungsschwelle muss eine positive Zahl sein',
        oneDayNeedSelected: 'Es muss mindestens ein Tag ausgewählt werden',
        nomoreTwoDaysSelected: 'Es können nicht mehr als zwei Tage ausgewählt werden',
        daysOfMonthRequired: 'Tage des Monats sind erforderlich',
        extraSettings: 'Zusätzliche Einstellungen',
        selectOnlyTwoDays: 'Wählen Sie bis zu zwei Tage aus',
        endOfMonth: 'Monatsende',
        success: {
          create: 'Maschine wurde erfolgreich erstellt',
          delete: 'Maschine wurde erfolgreich gelöscht',
          update: 'Maschine wurde erfolgreich aktualisiert',
        },
        error: {
          create: 'Fehler beim Erstellen der Maschine mit Status "{{status}}": {{message}}',
          update: 'Fehler beim Aktualisieren der Maschine mit Status "{{status}}": {{message}}',
          delete: 'Fehler beim Löschen der Maschine mit Status "{{status}}": {{message}}',
        },
      },

      machinesPage: {
        newMachine: 'Neue Maschine',
      },

      machineActivityTable: {
        activityTimestamp: 'Aktivitätszeitstempel',
        activityUnits: 'Einheiten',
        activityCost: 'Aktivitätskosten',
        activityStatus: 'Aktivitätsstatus',
        activityNote: 'Aktivitätsnotiz',
      },

      memberDialog: {
        editMember: 'Teilnehmer bearbeiten',
        onboardMember: 'Hier einen neuen Teilnehmer hinzufügen und dessen Rollen im PayperChain-netzwerk festlegen.',
        currentlyEditing: 'Aktuell bearbeitet:',
        memberUpdateSuccess: 'Mitgliedsstatus erfolgreich aktualisiert',
        updateMemberError: 'Fehler beim Aktualisieren des Mitgliedsstatus',
        updateMemberErrorStatus: 'Fehler beim Aktualisieren des Mitgliedsstatus mit Status',
        suspend: 'Sperren',
        activate: 'Aktivieren',
        currentStatus: 'Aktueller Status: ',
        memberRolesUpdatedSuccess: 'Rollen des Teilnehmers erfolgreich aktualisiert',
        updateMemberRolesError: 'Fehler beim Aktualisieren der Rolle des Teilnehmers',
        updateMemberRolesErrorStatus: 'Fehler beim Aktualisieren der Rolle des Teilnehmers mit Status',
        updateRoles: 'Rollen aktualisieren',
      },

      membersTable: {
        identity: 'Identität',
        membershipRequestedSuccess: 'Mitgliedschaft erfolgreich angefordert',
        membershipRequestedError: 'Fehler bei der Anforderung der Mitgliedschaft',
        membershipRequestedErrorStatus: 'Fehler bei der Anforderung der Mitgliedschaft mit Status',
        networkMembership: 'Sie sind kein Teil des PayperChain-Netzwerks',
        requestMembership: 'Mitgliedschaft anfordern',
        networkCreateSuccess: 'Netzwerk erfolgreich erstellt',
        networkCreateError: 'Fehler beim Erstellen des Netzwerks',
        networkCreateErrorStatus: 'Fehler beim Erstellen des Netzwerks mit Status',
        networkExistance: 'Das PayperChain-Netzwerk existiert noch nicht',
        createNetwork: 'PayperChain-Netzwerk erstellen',
      },

      aboutPage: {
        title: 'Über uns',
        security: 'Wir machen Pay-Per-Use sicher.',
        flexibility: 'Mit der neuesten Blockchain-Technologie bieten wir vollautomatisierte Zahlungsabläufe.',
        investment:
          'Investieren Sie – unabhängig von Ihrer aktuellen Auftragslage. Bringen Sie mehr Flexibilität in Ihren Cashflow. Steigern Sie Ihre langfristigen Einnahmen.',
        contactUs: 'Kontaktieren Sie uns!',
      },

      networkPage: {
        activeNode: 'Aktiver Knoten',
        notary: 'Notar',
        otherNodes: 'Weitere Knoten:',
        networkInfo: 'Netzwerkinformationen',
        organization: 'Organisation',
        locality: 'Ort',
        country: 'Land',
      },

      walletPage: {
        summary: 'Zusammenfassung',
        paymentCount: 'Anzahl der Zahlungen',
        TotalAmountInitiated: 'Initiierter Betrag',
        TotalAmountPending: 'Ausstehender Betrag',
        TotalAmountConfirmed: 'Bestätigter Betrag',
      },

      errorPage: {
        error: 'Fehler',
        pageNotFound: 'Die von Ihnen gesuchte Seite konnte nicht gefunden werden.',
        click: 'Klicken Sie',
        here: 'hier',
        accessPage: 'um die Startseite aufzurufen',
      },
    },
  },
};
