/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CordaX500NameDto,
    CordaX500NameDtoFromJSON,
    CordaX500NameDtoFromJSONTyped,
    CordaX500NameDtoToJSON,
} from './CordaX500NameDto';

/**
 * 
 * @export
 * @interface PaymentSummaryDto
 */
export interface PaymentSummaryDto {
    /**
     * 
     * @type {CordaX500NameDto}
     * @memberof PaymentSummaryDto
     */
    machineOwner: CordaX500NameDto;
    /**
     * 
     * @type {CordaX500NameDto}
     * @memberof PaymentSummaryDto
     */
    machineUser: CordaX500NameDto;
    /**
     * Name of the machine.
     * @type {string}
     * @memberof PaymentSummaryDto
     */
    machineName: string;
    /**
     * Total activity units.
     * @type {number}
     * @memberof PaymentSummaryDto
     */
    totalUnits: number;
    /**
     * Count of the payments.
     * @type {number}
     * @memberof PaymentSummaryDto
     */
    totalPayments: number;
    /**
     * Total amount of all payments in state 'initiated'.
     * @type {number}
     * @memberof PaymentSummaryDto
     */
    totalAmountInitiated: number;
    /**
     * Total amount of all payments in state 'pending'.
     * @type {number}
     * @memberof PaymentSummaryDto
     */
    totalAmountPending: number;
    /**
     * Total amount of all payments in state 'confirmed'.
     * @type {number}
     * @memberof PaymentSummaryDto
     */
    totalAmountConfirmed: number;
}

export function PaymentSummaryDtoFromJSON(json: any): PaymentSummaryDto {
    return PaymentSummaryDtoFromJSONTyped(json, false);
}

export function PaymentSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machineOwner': CordaX500NameDtoFromJSON(json['machineOwner']),
        'machineUser': CordaX500NameDtoFromJSON(json['machineUser']),
        'machineName': json['machineName'],
        'totalUnits': json['totalUnits'],
        'totalPayments': json['totalPayments'],
        'totalAmountInitiated': json['totalAmountInitiated'],
        'totalAmountPending': json['totalAmountPending'],
        'totalAmountConfirmed': json['totalAmountConfirmed'],
    };
}

export function PaymentSummaryDtoToJSON(value?: PaymentSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machineOwner': CordaX500NameDtoToJSON(value.machineOwner),
        'machineUser': CordaX500NameDtoToJSON(value.machineUser),
        'machineName': value.machineName,
        'totalUnits': value.totalUnits,
        'totalPayments': value.totalPayments,
        'totalAmountInitiated': value.totalAmountInitiated,
        'totalAmountPending': value.totalAmountPending,
        'totalAmountConfirmed': value.totalAmountConfirmed,
    };
}

