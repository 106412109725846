/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineActivityRequest
 */
export interface MachineActivityRequest {
    /**
     * Number of chargeable machine units.
     * @type {number}
     * @memberof MachineActivityRequest
     */
    activityUnits: number;
    /**
     * Optional note for the recorded machine activity.
     * @type {string}
     * @memberof MachineActivityRequest
     */
    activityNote?: string;
}

export function MachineActivityRequestFromJSON(json: any): MachineActivityRequest {
    return MachineActivityRequestFromJSONTyped(json, false);
}

export function MachineActivityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineActivityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activityUnits': json['activityUnits'],
        'activityNote': !exists(json, 'activityNote') ? undefined : json['activityNote'],
    };
}

export function MachineActivityRequestToJSON(value?: MachineActivityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activityUnits': value.activityUnits,
        'activityNote': value.activityNote,
    };
}

