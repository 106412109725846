/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Status of the membership.
 * @export
 */
export const MembershipStatusEnum = {
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED'
} as const;
export type MembershipStatusEnum = typeof MembershipStatusEnum[keyof typeof MembershipStatusEnum];


export function MembershipStatusEnumFromJSON(json: any): MembershipStatusEnum {
    return MembershipStatusEnumFromJSONTyped(json, false);
}

export function MembershipStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MembershipStatusEnum {
    return json as MembershipStatusEnum;
}

export function MembershipStatusEnumToJSON(value?: MembershipStatusEnum | null): any {
    return value as any;
}

