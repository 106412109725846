import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { Title } from '../assets/Title';

/**
 * Definition of the about view component.
 */
export function ErrorPage(): JSX.Element {
  // Trnaslations
  const { t } = useTranslation();
  const hereTxt = t('errorPage.here');
  const errorTxt = t('errorPage.error');
  const clickTxt = t('errorPage.click');
  const accessPageTxt = t('errorPage.accessPage');
  const pageNotFoundTxt = t('errorPage.pageNotFound');

  return (
    <React.Fragment>
      <Title>{errorTxt}</Title>
      <br />
      <Typography variant="body1">
        {pageNotFoundTxt}
        <br />
        <br />
        {clickTxt} <Link to="/">{hereTxt}</Link> {accessPageTxt}
      </Typography>
    </React.Fragment>
  );
}
