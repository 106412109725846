import React from 'react';
import get from 'lodash/get';
import { UseFormikType } from './useFormikType';
import { Checkbox, FormControl, FormControlLabel, FormControlProps, FormGroup, FormLabel } from '@material-ui/core';

export interface CheckboxesData {
  id: string;
  label: string;
  disabled?: boolean;
}

// Extend <FormControlProps> property type
type FormikFormControlProps = FormControlProps & {
  formik: UseFormikType;
  label?: string;
  checkboxData: CheckboxesData[];
};

/**
 * Wrapper of <FormControl> with an InputLabel and Select to create a dropdown input for a form
 * @param props FormControl props extended by `label` for label description and `multiple` to create a multi-select dropdown
 */
export default function FormikFormControlCheckbox(props: FormikFormControlProps): JSX.Element {
  const { formik, label, checkboxData, ...other } = props;

  return (
    <FormControl {...other}>
      <FormLabel>{label}</FormLabel>
      <FormGroup>
        {checkboxData.map((data, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                color="primary"
                checked={get(formik.values, data.id, '')}
                onChange={formik.handleChange(data.id)}
                disabled={data.disabled}
              />
            }
            label={data.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
