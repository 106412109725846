import React, { useEffect, useMemo, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import red from '@material-ui/core/colors/red';
import { createTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import { BrowserRouter } from 'react-router-dom';
import { KeycloakInitOptions } from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Dashboard } from './components/dashboard/Dashboard';
import keycloak from './common/keycloak';

// Log Token and Keycloak events for debugging
const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error);
};
const tokenLogger = (tokens: unknown) => {
  console.log('onKeycloakTokens', tokens);
};

function App(): JSX.Element {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [isDarkMode, setIsDarkMode] = useState(prefersDarkMode);

  // Redirect to keycloak login page if not logged in
  const keycloakProviderInitConfig: KeycloakInitOptions = {
    onLoad: 'login-required',
  };

  useEffect(() => {
    setIsDarkMode(prefersDarkMode);
  }, [prefersDarkMode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          type: isDarkMode ? 'dark' : 'light',
          primary: {
            // main: isDarkMode ? '#3377AC' : '#00477e',
            main: '#00477e',
          },
          secondary: {
            // main: isDarkMode ? '#f48fb1' : '#b3b3b3',
            main: isDarkMode ? '#C79797' : '#b3b3b3',
          },
          error: {
            main: red.A400,
          },
          background: {
            // default: isDarkMode ? '#121212' : '#fff',
            default: isDarkMode ? '#141414' : '#fff',
          },
        },
        overrides: {
          MuiPaper: {
            root: {
              backgroundColor: isDarkMode ? '#1C1C1C' : '#fff',
            },
          },
        },
      }),
    [isDarkMode]
  );

  function themeToggle() {
    setIsDarkMode((prevMode) => !prevMode);
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
      initOptions={keycloakProviderInitConfig}
    >
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <BrowserRouter>
              <Dashboard isDarkMode={isDarkMode} themeToggle={themeToggle} />
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
