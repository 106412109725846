import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';

import { useConfig } from '../../common/config';
import { RouteType } from '../pages/routes';

/**
 * Defines the routes an the navigation bar on the left hand side.
 */
export const RoutingItems = function (): JSX.Element {
  const location = useLocation();
  const { routes } = useConfig();

  return (
    <div>
      {routes.map((route: RouteType, index: number) => (
        <ListItem button key={index} component={Link} to={route.path} selected={location.pathname === route.path}>
          <ListItemIcon>
            <route.icon />
          </ListItemIcon>
          <ListItemText primary={route.label} />
        </ListItem>
      ))}
    </div>
  );
};
