/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CordaX500NameDto,
    CordaX500NameDtoFromJSON,
    CordaX500NameDtoFromJSONTyped,
    CordaX500NameDtoToJSON,
} from './CordaX500NameDto';
import {
    PaymentInformationDto,
    PaymentInformationDtoFromJSON,
    PaymentInformationDtoFromJSONTyped,
    PaymentInformationDtoToJSON,
} from './PaymentInformationDto';

/**
 * 
 * @export
 * @interface MachineRequest
 */
export interface MachineRequest {
    /**
     * 
     * @type {CordaX500NameDto}
     * @memberof MachineRequest
     */
    machineOwner: CordaX500NameDto;
    /**
     * 
     * @type {CordaX500NameDto}
     * @memberof MachineRequest
     */
    machineUser: CordaX500NameDto;
    /**
     * Human-readable name of the machine.
     * @type {string}
     * @memberof MachineRequest
     */
    machineName: string;
    /**
     * Current price in EUR for one unit.
     * @type {number}
     * @memberof MachineRequest
     */
    pricePerUnit: number;
    /**
     * Trigger threshold for automatic payments.
     * @type {number}
     * @memberof MachineRequest
     */
    paymentThreshold?: number;
    /**
     * 
     * @type {CordaX500NameDto}
     * @memberof MachineRequest
     */
    paymentProvider: CordaX500NameDto;
    /**
     * 
     * @type {PaymentInformationDto}
     * @memberof MachineRequest
     */
    paymentInformation?: PaymentInformationDto;
    /**
     * Array with X.500 names of insurers.
     * @type {Array<CordaX500NameDto>}
     * @memberof MachineRequest
     */
    insurers: Array<CordaX500NameDto>;
    /**
     * Array with X.500 names of machine observers.
     * @type {Array<CordaX500NameDto>}
     * @memberof MachineRequest
     */
    machineObservers: Array<CordaX500NameDto>;
}

export function MachineRequestFromJSON(json: any): MachineRequest {
    return MachineRequestFromJSONTyped(json, false);
}

export function MachineRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machineOwner': CordaX500NameDtoFromJSON(json['machineOwner']),
        'machineUser': CordaX500NameDtoFromJSON(json['machineUser']),
        'machineName': json['machineName'],
        'pricePerUnit': json['pricePerUnit'],
        'paymentThreshold': !exists(json, 'paymentThreshold') ? undefined : json['paymentThreshold'],
        'paymentProvider': CordaX500NameDtoFromJSON(json['paymentProvider']),
        'paymentInformation': !exists(json, 'paymentInformation') ? undefined : PaymentInformationDtoFromJSON(json['paymentInformation']),
        'insurers': ((json['insurers'] as Array<any>).map(CordaX500NameDtoFromJSON)),
        'machineObservers': ((json['machineObservers'] as Array<any>).map(CordaX500NameDtoFromJSON)),
    };
}

export function MachineRequestToJSON(value?: MachineRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machineOwner': CordaX500NameDtoToJSON(value.machineOwner),
        'machineUser': CordaX500NameDtoToJSON(value.machineUser),
        'machineName': value.machineName,
        'pricePerUnit': value.pricePerUnit,
        'paymentThreshold': value.paymentThreshold,
        'paymentProvider': CordaX500NameDtoToJSON(value.paymentProvider),
        'paymentInformation': PaymentInformationDtoToJSON(value.paymentInformation),
        'insurers': ((value.insurers as Array<any>).map(CordaX500NameDtoToJSON)),
        'machineObservers': ((value.machineObservers as Array<any>).map(CordaX500NameDtoToJSON)),
    };
}

