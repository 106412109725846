import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { useStyles } from '../Styles';

export function LanguageSelector(): JSX.Element {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const classes = useStyles();

  const handleLanguageChange = (event: React.MouseEvent<HTMLElement>, newLanguage: string | null) => {
    if (newLanguage !== null) {
      setLanguage(newLanguage);
      i18n.changeLanguage(newLanguage);
    }
  };

  return (
    <div className={classes.toggleContainer}>
      <ToggleButtonGroup
        value={language}
        exclusive
        onChange={handleLanguageChange}
        aria-label="language selection"
        className={classes.toggleButtonGroup}
      >
        <ToggleButton value="en" aria-label="english" className={classes.toggleButton}>
          EN
        </ToggleButton>
        <ToggleButton value="de" aria-label="german" className={classes.toggleButton}>
          DE
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

export default LanguageSelector;
