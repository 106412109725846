/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsuranceInvoiceDetailsDto,
    InsuranceInvoiceDetailsDtoFromJSON,
    InsuranceInvoiceDetailsDtoFromJSONTyped,
    InsuranceInvoiceDetailsDtoToJSON,
} from './InsuranceInvoiceDetailsDto';
import {
    InsuranceInvoiceStatusEnum,
    InsuranceInvoiceStatusEnumFromJSON,
    InsuranceInvoiceStatusEnumFromJSONTyped,
    InsuranceInvoiceStatusEnumToJSON,
} from './InsuranceInvoiceStatusEnum';

/**
 * 
 * @export
 * @interface InsuranceInvoiceUpdateRequest
 */
export interface InsuranceInvoiceUpdateRequest {
    /**
     * 
     * @type {InsuranceInvoiceStatusEnum}
     * @memberof InsuranceInvoiceUpdateRequest
     */
    invoiceStatus: InsuranceInvoiceStatusEnum;
    /**
     * 
     * @type {InsuranceInvoiceDetailsDto}
     * @memberof InsuranceInvoiceUpdateRequest
     */
    invoiceDetails: InsuranceInvoiceDetailsDto;
}

export function InsuranceInvoiceUpdateRequestFromJSON(json: any): InsuranceInvoiceUpdateRequest {
    return InsuranceInvoiceUpdateRequestFromJSONTyped(json, false);
}

export function InsuranceInvoiceUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceInvoiceUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoiceStatus': InsuranceInvoiceStatusEnumFromJSON(json['invoiceStatus']),
        'invoiceDetails': InsuranceInvoiceDetailsDtoFromJSON(json['invoiceDetails']),
    };
}

export function InsuranceInvoiceUpdateRequestToJSON(value?: InsuranceInvoiceUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoiceStatus': InsuranceInvoiceStatusEnumToJSON(value.invoiceStatus),
        'invoiceDetails': InsuranceInvoiceDetailsDtoToJSON(value.invoiceDetails),
    };
}

