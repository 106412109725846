/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * List with all roles of the membership.
 * @export
 */
export const MembershipRoleEnum = {
    Bno: 'BNO',
    PaymentProvider: 'PAYMENT_PROVIDER',
    MachineOwner: 'MACHINE_OWNER',
    MachineUser: 'MACHINE_USER',
    Insurer: 'INSURER',
    MachineObserver: 'MACHINE_OBSERVER'
} as const;
export type MembershipRoleEnum = typeof MembershipRoleEnum[keyof typeof MembershipRoleEnum];


export function MembershipRoleEnumFromJSON(json: any): MembershipRoleEnum {
    return MembershipRoleEnumFromJSONTyped(json, false);
}

export function MembershipRoleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MembershipRoleEnum {
    return json as MembershipRoleEnum;
}

export function MembershipRoleEnumToJSON(value?: MembershipRoleEnum | null): any {
    return value as any;
}

