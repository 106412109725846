/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorInfo,
    ErrorInfoFromJSON,
    ErrorInfoToJSON,
    InsuranceCreateRequest,
    InsuranceCreateRequestFromJSON,
    InsuranceCreateRequestToJSON,
    InsuranceDto,
    InsuranceDtoFromJSON,
    InsuranceDtoToJSON,
    InsuranceInvoiceCreateRequest,
    InsuranceInvoiceCreateRequestFromJSON,
    InsuranceInvoiceCreateRequestToJSON,
    InsuranceInvoiceDto,
    InsuranceInvoiceDtoFromJSON,
    InsuranceInvoiceDtoToJSON,
    InsuranceInvoiceUpdateRequest,
    InsuranceInvoiceUpdateRequestFromJSON,
    InsuranceInvoiceUpdateRequestToJSON,
    InsuranceUpdateRequest,
    InsuranceUpdateRequestFromJSON,
    InsuranceUpdateRequestToJSON,
} from '../models';

export interface CreateInsuranceRequest {
    insuranceCreateRequest: InsuranceCreateRequest;
}

export interface CreateInsuranceInvoiceRequest {
    insuranceInvoiceCreateRequest: InsuranceInvoiceCreateRequest;
}

export interface GetInsuranceRequest {
    insuranceExternalId: string;
}

export interface GetInsuranceInvoiceRequest {
    invoiceId: string;
}

export interface GetInsuranceInvoicesRequest {
    insuranceExternalId?: string;
}

export interface UpdateInsuranceRequest {
    insuranceExternalId: string;
    insuranceUpdateRequest: InsuranceUpdateRequest;
}

export interface UpdateInsuranceInvoiceRequest {
    invoiceId: string;
    insuranceInvoiceUpdateRequest: InsuranceInvoiceUpdateRequest;
}

/**
 * 
 */
export class InsurancesApi extends runtime.BaseAPI {

    /**
     * Creates an insurance.
     */
    async createInsuranceRaw(requestParameters: CreateInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.insuranceCreateRequest === null || requestParameters.insuranceCreateRequest === undefined) {
            throw new runtime.RequiredError('insuranceCreateRequest','Required parameter requestParameters.insuranceCreateRequest was null or undefined when calling createInsurance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/insurances`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsuranceCreateRequestToJSON(requestParameters.insuranceCreateRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates an insurance.
     */
    async createInsurance(insuranceCreateRequest: InsuranceCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.createInsuranceRaw({ insuranceCreateRequest: insuranceCreateRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new invoice.
     */
    async createInsuranceInvoiceRaw(requestParameters: CreateInsuranceInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.insuranceInvoiceCreateRequest === null || requestParameters.insuranceInvoiceCreateRequest === undefined) {
            throw new runtime.RequiredError('insuranceInvoiceCreateRequest','Required parameter requestParameters.insuranceInvoiceCreateRequest was null or undefined when calling createInsuranceInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/insurances/invoices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InsuranceInvoiceCreateRequestToJSON(requestParameters.insuranceInvoiceCreateRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a new invoice.
     */
    async createInsuranceInvoice(insuranceInvoiceCreateRequest: InsuranceInvoiceCreateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.createInsuranceInvoiceRaw({ insuranceInvoiceCreateRequest: insuranceInvoiceCreateRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a specific insurance.
     */
    async getInsuranceRaw(requestParameters: GetInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<InsuranceDto>> {
        if (requestParameters.insuranceExternalId === null || requestParameters.insuranceExternalId === undefined) {
            throw new runtime.RequiredError('insuranceExternalId','Required parameter requestParameters.insuranceExternalId was null or undefined when calling getInsurance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/insurances/{insuranceExternalId}`.replace(`{${"insuranceExternalId"}}`, encodeURIComponent(String(requestParameters.insuranceExternalId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsuranceDtoFromJSON(jsonValue));
    }

    /**
     * Returns a specific insurance.
     */
    async getInsurance(insuranceExternalId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<InsuranceDto> {
        const response = await this.getInsuranceRaw({ insuranceExternalId: insuranceExternalId }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a specific invoice.
     */
    async getInsuranceInvoiceRaw(requestParameters: GetInsuranceInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<InsuranceInvoiceDto>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling getInsuranceInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/insurances/invoices/{invoiceId}`.replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InsuranceInvoiceDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve a specific invoice.
     */
    async getInsuranceInvoice(invoiceId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<InsuranceInvoiceDto> {
        const response = await this.getInsuranceInvoiceRaw({ invoiceId: invoiceId }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve all invoices or optionally filtered for a given insurance.
     */
    async getInsuranceInvoicesRaw(requestParameters: GetInsuranceInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<InsuranceInvoiceDto>>> {
        const queryParameters: any = {};

        if (requestParameters.insuranceExternalId !== undefined) {
            queryParameters['insuranceExternalId'] = requestParameters.insuranceExternalId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/insurances/invoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InsuranceInvoiceDtoFromJSON));
    }

    /**
     * Retrieve all invoices or optionally filtered for a given insurance.
     */
    async getInsuranceInvoices(insuranceExternalId?: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<InsuranceInvoiceDto>> {
        const response = await this.getInsuranceInvoicesRaw({ insuranceExternalId: insuranceExternalId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of all insurances.
     */
    async getInsurancesRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<InsuranceDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/insurances`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InsuranceDtoFromJSON));
    }

    /**
     * Returns a list of all insurances.
     */
    async getInsurances(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<InsuranceDto>> {
        const response = await this.getInsurancesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates an insurance.
     */
    async updateInsuranceRaw(requestParameters: UpdateInsuranceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.insuranceExternalId === null || requestParameters.insuranceExternalId === undefined) {
            throw new runtime.RequiredError('insuranceExternalId','Required parameter requestParameters.insuranceExternalId was null or undefined when calling updateInsurance.');
        }

        if (requestParameters.insuranceUpdateRequest === null || requestParameters.insuranceUpdateRequest === undefined) {
            throw new runtime.RequiredError('insuranceUpdateRequest','Required parameter requestParameters.insuranceUpdateRequest was null or undefined when calling updateInsurance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/insurances/{insuranceExternalId}`.replace(`{${"insuranceExternalId"}}`, encodeURIComponent(String(requestParameters.insuranceExternalId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InsuranceUpdateRequestToJSON(requestParameters.insuranceUpdateRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Updates an insurance.
     */
    async updateInsurance(insuranceExternalId: string, insuranceUpdateRequest: InsuranceUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.updateInsuranceRaw({ insuranceExternalId: insuranceExternalId, insuranceUpdateRequest: insuranceUpdateRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Updates an insurance invoice.
     */
    async updateInsuranceInvoiceRaw(requestParameters: UpdateInsuranceInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling updateInsuranceInvoice.');
        }

        if (requestParameters.insuranceInvoiceUpdateRequest === null || requestParameters.insuranceInvoiceUpdateRequest === undefined) {
            throw new runtime.RequiredError('insuranceInvoiceUpdateRequest','Required parameter requestParameters.insuranceInvoiceUpdateRequest was null or undefined when calling updateInsuranceInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/insurances/invoices/{invoiceId}`.replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InsuranceInvoiceUpdateRequestToJSON(requestParameters.insuranceInvoiceUpdateRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Updates an insurance invoice.
     */
    async updateInsuranceInvoice(invoiceId: string, insuranceInvoiceUpdateRequest: InsuranceInvoiceUpdateRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.updateInsuranceInvoiceRaw({ invoiceId: invoiceId, insuranceInvoiceUpdateRequest: insuranceInvoiceUpdateRequest }, initOverrides);
        return await response.value();
    }

}
