/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsuranceDetailsDto,
    InsuranceDetailsDtoFromJSON,
    InsuranceDetailsDtoFromJSONTyped,
    InsuranceDetailsDtoToJSON,
} from './InsuranceDetailsDto';
import {
    InsuranceMachineDetailsDto,
    InsuranceMachineDetailsDtoFromJSON,
    InsuranceMachineDetailsDtoFromJSONTyped,
    InsuranceMachineDetailsDtoToJSON,
} from './InsuranceMachineDetailsDto';
import {
    InsurancePayPerUseDetailsDto,
    InsurancePayPerUseDetailsDtoFromJSON,
    InsurancePayPerUseDetailsDtoFromJSONTyped,
    InsurancePayPerUseDetailsDtoToJSON,
} from './InsurancePayPerUseDetailsDto';
import {
    InsurancePaymentDetailsDto,
    InsurancePaymentDetailsDtoFromJSON,
    InsurancePaymentDetailsDtoFromJSONTyped,
    InsurancePaymentDetailsDtoToJSON,
} from './InsurancePaymentDetailsDto';
import {
    InsuranceStatusEnum,
    InsuranceStatusEnumFromJSON,
    InsuranceStatusEnumFromJSONTyped,
    InsuranceStatusEnumToJSON,
} from './InsuranceStatusEnum';

/**
 * 
 * @export
 * @interface InsuranceUpdateRequest
 */
export interface InsuranceUpdateRequest {
    /**
     * 
     * @type {InsuranceStatusEnum}
     * @memberof InsuranceUpdateRequest
     */
    insuranceStatus: InsuranceStatusEnum;
    /**
     * 
     * @type {InsuranceDetailsDto}
     * @memberof InsuranceUpdateRequest
     */
    insuranceDetails: InsuranceDetailsDto;
    /**
     * 
     * @type {InsuranceMachineDetailsDto}
     * @memberof InsuranceUpdateRequest
     */
    machineDetails: InsuranceMachineDetailsDto;
    /**
     * 
     * @type {InsurancePaymentDetailsDto}
     * @memberof InsuranceUpdateRequest
     */
    paymentDetails: InsurancePaymentDetailsDto;
    /**
     * 
     * @type {InsurancePayPerUseDetailsDto}
     * @memberof InsuranceUpdateRequest
     */
    payPerUseDetails: InsurancePayPerUseDetailsDto;
}

export function InsuranceUpdateRequestFromJSON(json: any): InsuranceUpdateRequest {
    return InsuranceUpdateRequestFromJSONTyped(json, false);
}

export function InsuranceUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'insuranceStatus': InsuranceStatusEnumFromJSON(json['insuranceStatus']),
        'insuranceDetails': InsuranceDetailsDtoFromJSON(json['insuranceDetails']),
        'machineDetails': InsuranceMachineDetailsDtoFromJSON(json['machineDetails']),
        'paymentDetails': InsurancePaymentDetailsDtoFromJSON(json['paymentDetails']),
        'payPerUseDetails': InsurancePayPerUseDetailsDtoFromJSON(json['payPerUseDetails']),
    };
}

export function InsuranceUpdateRequestToJSON(value?: InsuranceUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'insuranceStatus': InsuranceStatusEnumToJSON(value.insuranceStatus),
        'insuranceDetails': InsuranceDetailsDtoToJSON(value.insuranceDetails),
        'machineDetails': InsuranceMachineDetailsDtoToJSON(value.machineDetails),
        'paymentDetails': InsurancePaymentDetailsDtoToJSON(value.paymentDetails),
        'payPerUseDetails': InsurancePayPerUseDetailsDtoToJSON(value.payPerUseDetails),
    };
}

