import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Chip, CircularProgress, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import MaterialTable, { Column } from 'material-table';
import Button from '@material-ui/core/Button';
import { dateComparer, formatCordaX500Name, formatDateTime } from '../../../common/format';
import { useTheme } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';

import { MembershipDto, MembershipRoleEnum } from '../../../generated';
import { MaterialTableIcons } from '../../assets/MaterialTableIcons';
import useNotifications from '../../assets/useNotifications';
import { getMembershipsApi } from '../../../common/keycloak';
import MemberStatusChip from './MemberStatusChip';
import { useConfig } from '../../../common/config';

export function MembersTable(props: {
  memberships: MembershipDto[];
  promiseInProgress: boolean;
  setEditMembershipDialog: (open: { open: boolean; membership: MembershipDto }) => void;
  reload: () => void;
}): JSX.Element {
  const theme = useTheme();
  const { membersPage } = useConfig();

  // Trnaslations
  const { t } = useTranslation();
  const rowTxt = t('row');
  const editTxt = t('edit');
  const rolesTxt = t('roles');
  const searchTxt = t('search');
  const createdTxt = t('created');
  const insurerTxt = t('insurer');
  const updatedTxt = t('updated');
  const containsTxt = t('contains');
  const selectDateTxt = t('selectDate');
  const machineUserTxt = t('machineUser');
  const machineOwnerTxt = t('machineOwner');
  const identityTxt = t('membersTable.identity');
  const machineObserverTxt = t('machineObserver');
  const paymentProviderTxt = t('paymentProvider');

  /**
   * Define the columns including format/type and filtering
   */
  const ColumnIdentity: Column<MembershipDto> = {
    title: identityTxt,
    field: 'identity.organization',
    render: (data: MembershipDto) => formatCordaX500Name(data.identity),
    filterPlaceholder: containsTxt,
    defaultSort: 'asc',
  };

  const ColumnStatus: Column<MembershipDto> = {
    title: 'Status',
    field: 'membershipStatus',
    filterPlaceholder: containsTxt,
    cellStyle: { textAlign: 'center' },
    headerStyle: { textAlign: 'center' },
    render: (data: MembershipDto) => <MemberStatusChip membershipStatus={data.membershipStatus} size="small" />,
  };

  function MembershipRoleChip(props: { membershipRole: MembershipRoleEnum }) {
    let label;
    switch (props.membershipRole) {
      case MembershipRoleEnum.Bno: {
        label = 'BNO';
        break;
      }
      case MembershipRoleEnum.MachineOwner: {
        label = machineOwnerTxt;
        break;
      }
      case MembershipRoleEnum.MachineUser: {
        label = machineUserTxt;
        break;
      }
      case MembershipRoleEnum.PaymentProvider: {
        label = paymentProviderTxt;
        break;
      }
      case MembershipRoleEnum.Insurer: {
        label = insurerTxt;
        break;
      }
      case MembershipRoleEnum.MachineObserver: {
        label = machineObserverTxt;
        break;
      }
      default: {
        label = props.membershipRole;
        break;
      }
    }

    return <Chip size="small" label={label} style={{ margin: theme.spacing(0.25) }} />;
  }

  const ColumnRoles: Column<MembershipDto> = {
    title: rolesTxt,
    field: 'membershipRoles',
    filterPlaceholder: containsTxt,
    render: (data: MembershipDto) => {
      return (
        <>
          {data.membershipRoles.map((role, index) => (
            <MembershipRoleChip key={index} membershipRole={role} />
          ))}
        </>
      );
    },
  };

  const ColumnCreated: Column<MembershipDto> = {
    title: createdTxt,
    field: 'created',
    type: 'date',
    render: (data: MembershipDto) => formatDateTime(data.created),
    customFilterAndSearch: (term: string, data: MembershipDto) => dateComparer(term, data.created),
    filterPlaceholder: selectDateTxt,
  };

  const ColumnUpdated: Column<MembershipDto> = {
    title: updatedTxt,
    field: 'updated',
    type: 'date',
    render: (data: MembershipDto) => formatDateTime(data.updated),
    customFilterAndSearch: (term: string, data: MembershipDto) => dateComparer(term, data.updated),
    filterPlaceholder: selectDateTxt,
  };

  const ColumnEditMemberships: Column<MembershipDto> = {
    title: editTxt,
    sorting: false,
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: (data: MembershipDto) => (
      <IconButton
        onClick={() => {
          props.setEditMembershipDialog({ open: true, membership: data });
        }}
      >
        <Edit />
      </IconButton>
    ),
  };

  const columnDefs: Column<MembershipDto>[] = [ColumnIdentity, ColumnStatus, ColumnRoles, ColumnCreated, ColumnUpdated];

  // Add edit button
  if (membersPage.canEditMembers) {
    columnDefs.push(ColumnEditMemberships);
  }

  /**
   * Show progress while loading and create/request button otherwise
   */
  const EmptyDataInformation = function () {
    if (props.promiseInProgress || props.memberships == null) return <CircularProgress />;

    if (membersPage.canCreateBusinessNetwork) return <CreateBusinessNetworkInformation onSuccess={props.reload} />;
    else return <RequestMembershipInformation onSuccess={props.reload} />;
  };

  return (
    <MaterialTable
      localization={{
        pagination: {
          labelRowsSelect: rowTxt,
        },
        toolbar: {
          searchPlaceholder: searchTxt,
        },
        body: {
          emptyDataSourceMessage: <EmptyDataInformation />,
        },
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      icons={MaterialTableIcons()}
      columns={columnDefs}
      data={props.memberships || []}
      options={{
        filtering: true,
        sorting: true,
        showTitle: false,
      }}
    />
  );
}

/**
 * Info that is shown to request membership
 */
function RequestMembershipInformation(props: { onSuccess: () => void }): JSX.Element {
  const { showError, showSuccess } = useNotifications();

  const { promiseInProgress } = usePromiseTracker({ area: 'membership-request', delay: 200 });

  // Trnaslations
  const { t } = useTranslation();
  const membershipRequestedSuccessTxt = t('membersTable.membershipRequestedSuccess');
  const membershipRequestedErrorTxt = t('membersTable.membershipRequestedError');
  const membershipRequestedErrorStatusTxt = t('membersTable.membershipRequestedErrorStatus');
  const networkMembershipTxt = t('membersTable.networkMembership');
  const requestMembershipTxt = t('membersTable.requestMembership');

  const handleClick = async function () {
    // Call MemberApi to request membership
    const requestMembership = async () => {
      const api = await getMembershipsApi();
      return api.requestMembership();
    };

    try {
      const result = await trackPromise(requestMembership(), 'membership-request');

      if (result) {
        showSuccess(membershipRequestedSuccessTxt);
        props.onSuccess();
      } else {
        showError(membershipRequestedErrorTxt);
      }
    } catch (e) {
      if (e instanceof Response) {
        showError(`${membershipRequestedErrorStatusTxt} "${e.status}": ${await e.text()}`);
      } else {
        showError(JSON.stringify(e));
      }
    }
  };

  return (
    <NoNetworkInformation
      onClick={handleClick}
      promiseInProgress={promiseInProgress}
      description={networkMembershipTxt}
      label={requestMembershipTxt}
    />
  );
}

/**
 * Info that is shown to create a new business network
 */
function CreateBusinessNetworkInformation(props: { onSuccess: () => void }): JSX.Element {
  const { showError, showSuccess } = useNotifications();

  const { promiseInProgress } = usePromiseTracker({ area: 'membership-request', delay: 200 });

  // Trnaslations
  const { t } = useTranslation();
  const networkCreateSuccessTxt = t('membersTable.networkCreateSuccess');
  const networkCreateErrorTxt = t('membersTable.networkCreateError');
  const networkCreateErrorStatusTxt = t('membersTable.networkCreateErrorStatus');
  const networkExistanceTxt = t('membersTable.networkExistance');
  const createNetworkTxt = t('membersTable.createNetwork');

  const handleClick = async function () {
    // Call MemberApi to create business network
    const createBusinessNetwork = async () => {
      const api = await getMembershipsApi();
      return api.createBusinessNetwork();
    };

    try {
      const result = await trackPromise(createBusinessNetwork(), 'membership-request');

      if (result) {
        showSuccess(networkCreateSuccessTxt);
        props.onSuccess();
      } else {
        showError(networkCreateErrorTxt);
      }
    } catch (e) {
      if (e instanceof Response) {
        showError(`${networkCreateErrorStatusTxt} "${e.status}": ${await e.text()}`);
      } else {
        showError(JSON.stringify(e));
      }
    }
  };

  return (
    <NoNetworkInformation
      onClick={handleClick}
      promiseInProgress={promiseInProgress}
      description={networkExistanceTxt}
      label={createNetworkTxt}
    />
  );
}

/**
 * Info that is shown when no business network is found
 */
function NoNetworkInformation(props: {
  onClick: () => void;
  promiseInProgress: boolean;
  label: string;
  description: string;
}): JSX.Element {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography>{props.description}</Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={props.onClick} disabled={props.promiseInProgress}>
          {props.promiseInProgress && <CircularProgress size={22} color="secondary" style={{ marginRight: 10 }} />}
          {props.label}
        </Button>
      </Grid>
    </Grid>
  );
}
