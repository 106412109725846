/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorInfo,
    ErrorInfoFromJSON,
    ErrorInfoToJSON,
    PaymentConfigurationDTO,
    PaymentConfigurationDTOFromJSON,
    PaymentConfigurationDTOToJSON,
    PaymentConfigurationRequest,
    PaymentConfigurationRequestFromJSON,
    PaymentConfigurationRequestToJSON,
} from '../models';

export interface GetPaymentConfigurationRequest {
    machineId: string;
}

export interface SetPaymentConfigurationRequest {
    paymentConfigurationRequest: PaymentConfigurationRequest;
}

/**
 * 
 */
export class PaymentConfigurationApi extends runtime.BaseAPI {

    /**
     * Returns the payment configuration
     */
    async getPaymentConfigurationRaw(requestParameters: GetPaymentConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<PaymentConfigurationDTO>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling getPaymentConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/payment/configuration/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentConfigurationDTOFromJSON(jsonValue));
    }

    /**
     * Returns the payment configuration
     */
    async getPaymentConfiguration(machineId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<PaymentConfigurationDTO> {
        const response = await this.getPaymentConfigurationRaw({ machineId: machineId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns the payment configuration
     */
    async getPaymentConfigurationsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PaymentConfigurationDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/payment/configuration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentConfigurationDTOFromJSON));
    }

    /**
     * Returns the payment configuration
     */
    async getPaymentConfigurations(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PaymentConfigurationDTO>> {
        const response = await this.getPaymentConfigurationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Sets the payment configuration
     */
    async setPaymentConfigurationRaw(requestParameters: SetPaymentConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.paymentConfigurationRequest === null || requestParameters.paymentConfigurationRequest === undefined) {
            throw new runtime.RequiredError('paymentConfigurationRequest','Required parameter requestParameters.paymentConfigurationRequest was null or undefined when calling setPaymentConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/payment/configuration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentConfigurationRequestToJSON(requestParameters.paymentConfigurationRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Sets the payment configuration
     */
    async setPaymentConfiguration(paymentConfigurationRequest: PaymentConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.setPaymentConfigurationRaw({ paymentConfigurationRequest: paymentConfigurationRequest }, initOverrides);
        return await response.value();
    }

}
