import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid, LinearProgress, MenuItem } from '@material-ui/core';

import {
  InsuranceDto,
  InsurancePaymentDetailsDto,
  InsurancePaymentIntervalEnum,
  InsuranceUpdateRequest,
} from '../../../../generated';
import useInsuranceApi from '../useInsuranceApi';
import FormikForm from '../../../assets/formik/FormikForm';
import FormikTextField from '../../../assets/formik/FormikTextField';
import FormikFormControlSelect from '../../../assets/formik/FormikFormControlSelect';

/**
 * JSX Element that is an overlay dialog to edit an insurance
 * @param props
 */
export default function PaymentDetailsDialog(props: {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  insuranceDto: InsuranceDto;
}): JSX.Element {
  const { promiseInProgress } = usePromiseTracker({ area: 'insurance-dialog', delay: 200 });

  // Trnaslations
  const { t } = useTranslation();
  const typeTxt = t('type');
  const noneTxt = t('none');
  const closeTxt = t('closeBtn');
  const saveTxt = t('saveBtn');
  const fieldExceedCharTxt = t('fieldExceedChar');
  const editInsuranceDetailsTxt = t('paymentDetailsDialog.editInsuranceDetails');
  const methodTxt = t('paymentDetailsDialog.method');
  const intervalTxt = t('paymentDetailsDialog.interval');

  const { handleUpdate } = useInsuranceApi();

  /**
   * Interface to store the current state of the form used by formik
   */
  interface InsurancePaymentDetailsDialogType {
    method?: string;
    type?: string;
    basis?: string;
    interval: string;
  }

  // Workaround to cast a string to an enum in Typescript
  // https://blog.oio.de/2014/02/28/typescript-accessing-enum-values-via-a-string/
  const InsurancePaymentIntervalEnumFromString: { [idx: string]: InsurancePaymentIntervalEnum } =
    InsurancePaymentIntervalEnum as never;

  const mapToInsuranceUpdateRequest = function (
    insuranceDetailsDialogType: InsurancePaymentDetailsDialogType
  ): InsuranceUpdateRequest {
    return {
      insuranceStatus: props.insuranceDto.insuranceStatus,
      insuranceDetails: props.insuranceDto.insuranceDetails,
      machineDetails: props.insuranceDto.machineDetails,
      paymentDetails: mapToInsurancePaymentDetailsDto(insuranceDetailsDialogType),
      payPerUseDetails: props.insuranceDto.payPerUseDetails,
    };
  };

  const mapToInsurancePaymentDetailsDto = function (
    insuranceDetailsDialogType: InsurancePaymentDetailsDialogType
  ): InsurancePaymentDetailsDto {
    return {
      method: insuranceDetailsDialogType.method,
      type: insuranceDetailsDialogType.type,
      basis: insuranceDetailsDialogType.basis,
      interval: InsurancePaymentIntervalEnumFromString[insuranceDetailsDialogType.interval],
    };
  };

  const mapToInsuranceDetailsDialogType = function (
    insuranceDto: InsurancePaymentDetailsDto
  ): InsurancePaymentDetailsDialogType {
    return {
      method: insuranceDto.method,
      type: insuranceDto.type,
      basis: insuranceDto.basis,
      interval: mapInsurancePaymentIntervalEnumValueToKey(insuranceDto.interval) || '',
    };
  };

  const mapInsurancePaymentIntervalEnumValueToKey = function (value?: string): string | undefined {
    if (!value) return;

    const entries = Object.entries(InsurancePaymentIntervalEnum);
    for (const entry of entries) {
      if (entry[1] == value) {
        return entry[0];
      }
    }
  };

  const validationSchema = yup.object({
    method: yup.string().max(128, fieldExceedCharTxt),
    type: yup.string().max(128, fieldExceedCharTxt),
    basis: yup.string().max(128, fieldExceedCharTxt),
  });

  const formik = useFormik({
    initialValues: {} as InsurancePaymentDetailsDialogType,
    validationSchema: validationSchema,
    onSubmit: (values) =>
      handleUpdate(props.insuranceDto.insuranceExternalId, mapToInsuranceUpdateRequest(values), props.onSave),
  });

  // Initialize form
  useEffect(() => {
    if (!props.open) return;
    formik.resetForm();

    const initialize = async function () {
      if (props.insuranceDto) {
        await formik.setValues(mapToInsuranceDetailsDialogType(props.insuranceDto.paymentDetails));
        await formik.validateForm();
      }
    };

    trackPromise(initialize(), 'insurance-dialog');

    // Adding 'formik' to dependencies creates an infinite loop as formik changes every render
  }, [props.open, props.insuranceDto]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
      {/* Without div the progress bar is not visible when scrollbar is shown */}
      {promiseInProgress && (
        <div>
          <LinearProgress />
        </div>
      )}
      <DialogTitle id="form-dialog-title">{editInsuranceDetailsTxt}</DialogTitle>
      <FormikForm formik={formik}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="method"
                variant="outlined"
                fullWidth
                label={methodTxt}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="type"
                variant="outlined"
                fullWidth
                label={typeTxt}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="basis"
                variant="outlined"
                fullWidth
                label="Basis"
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikFormControlSelect
                formik={formik}
                id="interval"
                variant="outlined"
                fullWidth
                label={intervalTxt}
                disabled={promiseInProgress}
              >
                <MenuItem value="">
                  <em>{noneTxt}</em>
                </MenuItem>
                {Object.keys(InsurancePaymentIntervalEnum).map((interval, index) => (
                  <MenuItem key={index} value={interval}>
                    {interval}
                  </MenuItem>
                ))}
              </FormikFormControlSelect>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.onClose}>
            {closeTxt}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={promiseInProgress || !formik.isValid}>
            {saveTxt}
          </Button>
        </DialogActions>
      </FormikForm>
    </Dialog>
  );
}
