import { SnackbarMessage, useSnackbar } from 'notistack';
import { useCallback } from 'react';

/**
 * Wraps notistack enqueueSnackbar for simplified usage
 */
export default function useNotifications(): {
  showInfo: (message: SnackbarMessage) => void;
  showSuccess: (message: SnackbarMessage) => void;
  showWarning: (message: SnackbarMessage) => void;
  showError: (message: SnackbarMessage) => void;
} {
  const { enqueueSnackbar } = useSnackbar();

  const showInfo = useCallback(
    (message: SnackbarMessage) => {
      enqueueSnackbar(message, { variant: 'info' });
    },
    [enqueueSnackbar]
  );

  const showSuccess = useCallback(
    (message: SnackbarMessage) => {
      enqueueSnackbar(message, { variant: 'success' });
    },
    [enqueueSnackbar]
  );

  const showWarning = useCallback(
    (message: SnackbarMessage) => {
      enqueueSnackbar(message, { variant: 'warning' });
    },
    [enqueueSnackbar]
  );

  const showError = useCallback(
    (message: SnackbarMessage) => {
      enqueueSnackbar(message, { variant: 'error' });
    },
    [enqueueSnackbar]
  );

  return { showInfo, showSuccess, showWarning, showError };
}
