/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CordaX500NameDto,
    CordaX500NameDtoFromJSON,
    CordaX500NameDtoFromJSONTyped,
    CordaX500NameDtoToJSON,
} from './CordaX500NameDto';
import {
    PaymentInformationDto,
    PaymentInformationDtoFromJSON,
    PaymentInformationDtoFromJSONTyped,
    PaymentInformationDtoToJSON,
} from './PaymentInformationDto';
import {
    PaymentStatusEnum,
    PaymentStatusEnumFromJSON,
    PaymentStatusEnumFromJSONTyped,
    PaymentStatusEnumToJSON,
} from './PaymentStatusEnum';

/**
 * 
 * @export
 * @interface PaymentDto
 */
export interface PaymentDto {
    /**
     * ID of the payment request.
     * @type {string}
     * @memberof PaymentDto
     */
    paymentId: string;
    /**
     * Timestamp of the payment state creation.
     * @type {Date}
     * @memberof PaymentDto
     */
    created: Date;
    /**
     * Timestamp of the last update to the payment state.
     * @type {Date}
     * @memberof PaymentDto
     */
    updated: Date;
    /**
     * ID of the related machine.
     * @type {string}
     * @memberof PaymentDto
     */
    machineId: string;
    /**
     * 
     * @type {CordaX500NameDto}
     * @memberof PaymentDto
     */
    machineOwner: CordaX500NameDto;
    /**
     * 
     * @type {CordaX500NameDto}
     * @memberof PaymentDto
     */
    machineUser: CordaX500NameDto;
    /**
     * Name of the machine that triggered the payment request.
     * @type {string}
     * @memberof PaymentDto
     */
    machineName: string;
    /**
     * Total activity units for this payment.
     * @type {number}
     * @memberof PaymentDto
     */
    totalUnits: number;
    /**
     * Total amount in EUR for the recorded units.
     * @type {number}
     * @memberof PaymentDto
     */
    totalAmount: number;
    /**
     * 
     * @type {PaymentInformationDto}
     * @memberof PaymentDto
     */
    payerPaymentInformation: PaymentInformationDto;
    /**
     * 
     * @type {PaymentInformationDto}
     * @memberof PaymentDto
     */
    payeePaymentInformation: PaymentInformationDto;
    /**
     * 
     * @type {PaymentStatusEnum}
     * @memberof PaymentDto
     */
    paymentStatus: PaymentStatusEnum;
}

export function PaymentDtoFromJSON(json: any): PaymentDto {
    return PaymentDtoFromJSONTyped(json, false);
}

export function PaymentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentId': json['paymentId'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'machineId': json['machineId'],
        'machineOwner': CordaX500NameDtoFromJSON(json['machineOwner']),
        'machineUser': CordaX500NameDtoFromJSON(json['machineUser']),
        'machineName': json['machineName'],
        'totalUnits': json['totalUnits'],
        'totalAmount': json['totalAmount'],
        'payerPaymentInformation': PaymentInformationDtoFromJSON(json['payerPaymentInformation']),
        'payeePaymentInformation': PaymentInformationDtoFromJSON(json['payeePaymentInformation']),
        'paymentStatus': PaymentStatusEnumFromJSON(json['paymentStatus']),
    };
}

export function PaymentDtoToJSON(value?: PaymentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentId': value.paymentId,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'machineId': value.machineId,
        'machineOwner': CordaX500NameDtoToJSON(value.machineOwner),
        'machineUser': CordaX500NameDtoToJSON(value.machineUser),
        'machineName': value.machineName,
        'totalUnits': value.totalUnits,
        'totalAmount': value.totalAmount,
        'payerPaymentInformation': PaymentInformationDtoToJSON(value.payerPaymentInformation),
        'payeePaymentInformation': PaymentInformationDtoToJSON(value.payeePaymentInformation),
        'paymentStatus': PaymentStatusEnumToJSON(value.paymentStatus),
    };
}

