import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Grid, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';

import { GetMachineActivitiesActivityStatusEnum, MachineActivityDto, MachineDto } from '../../../generated';
import { formatCordaX500Name, formatDateTime } from '../../../common/format';
import { MachineActivityTable } from '../../assets/MachineActivityTable';
import { DetailsBlock } from '../../assets/details/DetailsBlock';
import { DetailsEntry } from '../../assets/details/DetailsEntry';
import useNotifications from '../../assets/useNotifications';
import { getMachinesApi } from '../../../common/keycloak';
import { useStyles } from '../../Styles';

export function MachineDetails(props: { machine: MachineDto }): JSX.Element {
  const classes = useStyles();

  const { showInfo } = useNotifications();
  const { promiseInProgress } = usePromiseTracker({ area: 'machine-details', delay: 200 });
  const [machineActivities, setMachineActivities] = useState([] as MachineActivityDto[]);

  // Trnaslations
  const { t } = useTranslation();
  const updatedTxt = t('updated');
  const createdTxt = t('created');
  const machineIdTxt = t('machineId');
  const timestampTxt = t('timestamp');
  const machineDetailsTxt = t('machineDetails');
  const machineObserversTxt = t('machineObservers');
  const downloadFailedTxt = t('machineDetails.downloadFailed');
  const downloadSuccessTxt = t('machineDetails.downloadSuccess');
  const machineActivitiesTxt = t('MachineDetails.machineActivities');

  // Load machine activities
  useEffect(() => {
    const loadMachineActivities = async function () {
      const machinesApi = await getMachinesApi();
      const machineActivities = await machinesApi.getMachineActivities(
        props.machine.machineId,
        GetMachineActivitiesActivityStatusEnum.Unpaid
      );
      setMachineActivities(machineActivities);
    };

    trackPromise(loadMachineActivities(), 'machine-details');
  }, [props.machine.machineId]);

  /**
   * Function to download machine activities CSV
   * @param machineId
   * @param filePrefix
   */
  async function downloadMachineActivity(machineId: string, filePrefix: string) {
    const api = await getMachinesApi();
    try {
      // Get csvData
      const csvData = await api.getMachineActivitiesCsv(machineId);

      // Create blob and add create object URL via File API
      const blob = new Blob([csvData], { type: 'text/csv' });
      const objectUrl = window.URL.createObjectURL(blob);

      // Create anchor element to initialize download
      const anchor = document.createElement('a');
      anchor.href = objectUrl;
      anchor.download = filePrefix + '_' + format(new Date(), 'yyyyMMdd_HHmmss') + '.csv';
      anchor.click();

      // Free object url
      window.URL.revokeObjectURL(objectUrl);

      showInfo(downloadSuccessTxt);
    } catch (error) {
      showInfo(downloadFailedTxt);
    }
  }

  return (
    <div className={classes.divTable}>
      <Grid item xs container direction="column" spacing={1}>
        <Grid item>
          <Paper className={classes.papertable}>
            <Grid container direction="row" spacing={4}>
              <Grid item md={6} xs={12}>
                <DetailsBlock title={machineDetailsTxt}>
                  <DetailsEntry property={machineIdTxt}>{props.machine.machineId}</DetailsEntry>
                  <DetailsEntry property={machineObserversTxt}>
                    <List dense disablePadding>
                      {props.machine.machineObservers.map((observer, index) => {
                        return (
                          <ListItem dense disableGutters key={index + 10000}>
                            <ListItemText>{formatCordaX500Name(observer)}</ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </DetailsEntry>
                </DetailsBlock>
              </Grid>
              <Grid item md={6} xs={12}>
                <DetailsBlock title={timestampTxt}>
                  <DetailsEntry property={createdTxt}>{formatDateTime(props.machine.created)}</DetailsEntry>
                  <DetailsEntry property={updatedTxt}>{formatDateTime(props.machine.updated)}</DetailsEntry>
                </DetailsBlock>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item>
          <Paper className={classes.papertable}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Typography gutterBottom variant="subtitle1">
                  {machineActivitiesTxt}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={<GetAppIcon />}
                  onClick={() => downloadMachineActivity(props.machine.machineId, props.machine.machineName)}
                >
                  CSV
                </Button>
              </Grid>
            </Grid>
            <MachineActivityTable machineActivities={machineActivities} promiseInProgress={promiseInProgress} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
