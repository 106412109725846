import React, { useEffect } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Grid, LinearProgress, MenuItem } from '@material-ui/core';

import FormikTextField from '../../../assets/formik/FormikTextField';
import FormikForm from '../../../assets/formik/FormikForm';
import useNotifications from '../../../assets/useNotifications';
import FormikFormControlSelect from '../../../assets/formik/FormikFormControlSelect';
import { NumberFormatEurInput } from '../../../assets/numberformat/NumberFormatEur';
import {
  InsuranceInvoiceCreateRequest,
  InsuranceInvoiceDetailsDto,
  InsuranceInvoiceDto,
  InsuranceInvoiceStatusEnum,
  InsuranceInvoiceUpdateRequest,
} from '../../../../generated';
import { getInsurancesApi } from '../../../../common/keycloak';
import { NumberFormatUnitsInput } from '../../../assets/numberformat/NumberFormatUnits';
import FormikKeyboardDateTimePicker from '../../../assets/formik/FormikKeyboardDateTimePicker';
import FormikKeyboardDatePicker from '../../../assets/formik/FormikKeyboardDatePicker';

/**
 * JSX Element that is an overlay dialog
 * @param props
 */
export default function InsuranceInvoiceDialog(props: {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  insuranceInvoice?: InsuranceInvoiceDto;
  insuranceExternalId: string;
}): JSX.Element {
  const { promiseInProgress } = usePromiseTracker({ area: 'insuranceInvoice-dialog', delay: 200 });
  const { showError, showSuccess } = useNotifications();

  // Trnaslations
  const { t } = useTranslation();
  const addTxt = t('add');
  const editTxt = t('edit');
  const saveBtnTxt = t('saveBtn');
  const closeBtnTxt = t('closeBtn');
  const ppuAmountTxt = t('insurance.ppuAmount');
  const periodEndTxt = t('insurance.periodEnd');
  const baseAmountTxt = t('insurance.baseAmount');
  const periodStartTxt = t('insurance.periodStart');
  const paymentDateTxt = t('insurance.paymentDate');
  const invoiceDateTxt = t('insurance.invoiceDate');
  const invoiceAmountTxt = t('insurance.invoiceAmount');
  const insuranceInvoiceTxt = t('insuranceInvoiceDialog.insuranceInvoice');
  const insuranceInvoiceIDTxt = t('insuranceInvoiceDialog.insuranceInvoiceID');
  const billedActivityUnitsTxt = t('insuranceInvoiceDialog.billedActivityUnits');
  const recordedActivityUnitsTxt = t('insuranceInvoiceDialog.recordedActivityUnits');
  const InsuranceInvoiceStatusEnumErrorTxt = t('insuranceInvoiceDialog.InsuranceInvoiceStatusEnumError');

  /**
   * Send updated insurance state via API when the save button is pressed
   */

  const handleUpdate = async function (insuranceInvoiceDialogType: InsuranceInvoiceDialogType) {
    if (!props.insuranceInvoice) return;
    const insuranceInvoice = props.insuranceInvoice;

    // Call MemberApi to send updated member
    const updateInsuranceInvoice = async () => {
      const insurancesApi = await getInsurancesApi();
      return insurancesApi.updateInsuranceInvoice(
        insuranceInvoice.invoiceId,
        mapToInsuranceInvoiceUpdateRequest(insuranceInvoiceDialogType)
      );
    };

    await executeTask(updateInsuranceInvoice, 'update');
  };

  /**
   * Create insurance invoice state via API when the save button is pressed
   */
  const handleCreate = async function (insuranceInvoiceDialogType: InsuranceInvoiceDialogType) {
    const createInsuranceInvoice = async () => {
      const insurancesApi = await getInsurancesApi();
      return insurancesApi.createInsuranceInvoice(mapToInsuranceInvoiceCreateRequest(insuranceInvoiceDialogType));
    };

    await executeTask(createInsuranceInvoice, 'create');
  };

  /**
   * Executes an async function and processes the output
   * @param fun
   * @param description
   */
  const executeTask = async function (fun: () => Promise<boolean>, description: string) {
    try {
      const result = await trackPromise(fun(), 'insuranceInvoice-dialog');

      if (result) {
        showSuccess(`Insurance invoice ${description}d successfully`);
        props.onSave();
      } else {
        showError(`Error while ${description}ing insurance invoice`);
      }
    } catch (e) {
      if (e instanceof Response) {
        showError(`Error while ${description}ing insurance invoice with status "${e.status}": ${await e.text()}`);
      } else {
        showError('' + e);
      }
    }
  };

  /**
   * Interface to store the current state of the form used by formik
   */
  interface InsuranceInvoiceDialogType {
    invoiceStatus: string;

    invoiceDetails: InsuranceInvoiceDetailsDto;
  }

  // Workaround to cast a string to an enum in Typescript
  // https://blog.oio.de/2014/02/28/typescript-accessing-enum-values-via-a-string/
  const InsuranceInvoiceStatusEnumFromString: { [idx: string]: InsuranceInvoiceStatusEnum } =
    InsuranceInvoiceStatusEnum as never;

  const mapToInsuranceInvoiceCreateRequest = function (
    insuranceInvoiceDialogType: InsuranceInvoiceDialogType
  ): InsuranceInvoiceCreateRequest {
    return {
      insuranceExternalId: props.insuranceExternalId,
      invoiceStatus: InsuranceInvoiceStatusEnumFromString[insuranceInvoiceDialogType.invoiceStatus],

      invoiceDetails: insuranceInvoiceDialogType.invoiceDetails,
    };
  };

  const mapToInsuranceInvoiceUpdateRequest = function (
    insuranceInvoiceDialogType: InsuranceInvoiceDialogType
  ): InsuranceInvoiceUpdateRequest {
    return {
      invoiceStatus: InsuranceInvoiceStatusEnumFromString[insuranceInvoiceDialogType.invoiceStatus],

      invoiceDetails: mapToInsuranceInvoiceDetailsDto(insuranceInvoiceDialogType.invoiceDetails),
    };
  };

  const mapToInsuranceInvoiceDetailsDto = function (
    insuranceInvoiceDetailsDialogType: InsuranceInvoiceDetailsDto
  ): InsuranceInvoiceDetailsDto {
    return {
      // Change 'null' to 'undefined' as autogenerated api can't handle 'null' for date
      invoiceDate: insuranceInvoiceDetailsDialogType.invoiceDate || undefined,
      paymentDate: insuranceInvoiceDetailsDialogType.paymentDate || undefined,
      periodStart: insuranceInvoiceDetailsDialogType.periodStart || undefined,
      periodEnd: insuranceInvoiceDetailsDialogType.periodEnd || undefined,
      invoiceAmount: insuranceInvoiceDetailsDialogType.invoiceAmount,
      baseAmount: insuranceInvoiceDetailsDialogType.baseAmount,
      payPerUseAmount: insuranceInvoiceDetailsDialogType.payPerUseAmount,
      billedActivityUnits: insuranceInvoiceDetailsDialogType.billedActivityUnits,
      recordedActivityUnits: insuranceInvoiceDetailsDialogType.recordedActivityUnits,
    };
  };

  const mapToInsuranceInvoiceDialogType = function (
    insuranceInvoiceDto: InsuranceInvoiceDto
  ): InsuranceInvoiceDialogType {
    return {
      invoiceStatus: mapInsuranceInvoiceStatusEnumValueToKey(insuranceInvoiceDto.invoiceStatus),

      invoiceDetails: insuranceInvoiceDto.invoiceDetails,
    };
  };

  const mapInsuranceInvoiceStatusEnumValueToKey = function (value: string): string {
    const entries = Object.entries(InsuranceInvoiceStatusEnum);
    for (const entry of entries) {
      if (entry[1] == value) {
        return entry[0];
      }
    }
    throw new Error(InsuranceInvoiceStatusEnumErrorTxt);
  };

  const validationSchema = yup.object({});

  const formik = useFormik({
    initialValues: {
      invoiceDetails: {},
    } as InsuranceInvoiceDialogType,
    validationSchema: validationSchema,
    onSubmit: props.insuranceInvoice ? handleUpdate : handleCreate,
  });

  // Initialize form
  useEffect(() => {
    if (!props.open) return;
    formik.resetForm();

    const initialize = async function () {
      if (props.insuranceInvoice) {
        await formik.setValues(mapToInsuranceInvoiceDialogType(props.insuranceInvoice));
        await formik.validateForm();
      }
    };

    trackPromise(initialize(), 'insuranceInvoice-dialog');

    // Adding 'formik' to dependencies creates an infinite loop as formik changes every render
  }, [props.open, props.insuranceInvoice]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
      {/* Without div the progress bar is not visible when scrollbar is shown */}
      {promiseInProgress && (
        <div>
          <LinearProgress />
        </div>
      )}
      <DialogTitle id="form-dialog-title">{`${
        props.insuranceInvoice ? editTxt : addTxt
      } ${insuranceInvoiceTxt}`}</DialogTitle>
      <FormikForm formik={formik}>
        <DialogContent>
          {props.insuranceInvoice?.invoiceId && (
            <DialogContentText style={{ marginBottom: 20, marginTop: -20 }}>
              {`${insuranceInvoiceIDTxt}: ${props.insuranceInvoice.invoiceId}`}
            </DialogContentText>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikFormControlSelect
                formik={formik}
                label="Status"
                id="invoiceStatus"
                variant="outlined"
                fullWidth
                required
                disabled={promiseInProgress}
              >
                {Object.keys(InsuranceInvoiceStatusEnum).map((status, index) => (
                  <MenuItem key={index} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </FormikFormControlSelect>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikKeyboardDatePicker
                formik={formik}
                id="invoiceDetails.invoiceDate"
                variant="inline"
                inputVariant="outlined"
                fullWidth
                label={invoiceDateTxt}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikKeyboardDatePicker
                formik={formik}
                id="invoiceDetails.paymentDate"
                variant="inline"
                inputVariant="outlined"
                fullWidth
                label={paymentDateTxt}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikKeyboardDateTimePicker
                formik={formik}
                id="invoiceDetails.periodStart"
                variant="inline"
                inputVariant="outlined"
                fullWidth
                label={periodStartTxt}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikKeyboardDateTimePicker
                formik={formik}
                id="invoiceDetails.periodEnd"
                variant="inline"
                inputVariant="outlined"
                fullWidth
                label={periodEndTxt}
                disabled={promiseInProgress}
              />
            </Grid>

            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="invoiceDetails.invoiceAmount"
                variant="outlined"
                fullWidth
                label={invoiceAmountTxt}
                InputProps={{
                  inputComponent: NumberFormatEurInput as never,
                }}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikTextField
                formik={formik}
                id="invoiceDetails.baseAmount"
                variant="outlined"
                fullWidth
                label={baseAmountTxt}
                InputProps={{
                  inputComponent: NumberFormatEurInput as never,
                }}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikTextField
                formik={formik}
                id="invoiceDetails.payPerUseAmount"
                variant="outlined"
                fullWidth
                label={ppuAmountTxt}
                InputProps={{
                  inputComponent: NumberFormatEurInput as never,
                }}
                disabled={promiseInProgress}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormikTextField
                formik={formik}
                id="invoiceDetails.billedActivityUnits"
                variant="outlined"
                fullWidth
                label={billedActivityUnitsTxt}
                InputProps={{
                  inputComponent: NumberFormatUnitsInput as never,
                }}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikTextField
                formik={formik}
                id="invoiceDetails.recordedActivityUnits"
                variant="outlined"
                fullWidth
                label={recordedActivityUnitsTxt}
                InputProps={{
                  inputComponent: NumberFormatUnitsInput as never,
                }}
                disabled={promiseInProgress}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.onClose}>
            {closeBtnTxt}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={promiseInProgress || !formik.isValid}>
            {saveBtnTxt}
          </Button>
        </DialogActions>
      </FormikForm>
    </Dialog>
  );
}
