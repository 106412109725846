/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsuranceInvoiceDetailsDto
 */
export interface InsuranceInvoiceDetailsDto {
    /**
     * 
     * @type {Date}
     * @memberof InsuranceInvoiceDetailsDto
     */
    invoiceDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InsuranceInvoiceDetailsDto
     */
    paymentDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InsuranceInvoiceDetailsDto
     */
    periodStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InsuranceInvoiceDetailsDto
     */
    periodEnd?: Date;
    /**
     * 
     * @type {number}
     * @memberof InsuranceInvoiceDetailsDto
     */
    invoiceAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InsuranceInvoiceDetailsDto
     */
    baseAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InsuranceInvoiceDetailsDto
     */
    payPerUseAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InsuranceInvoiceDetailsDto
     */
    billedActivityUnits?: number;
    /**
     * 
     * @type {number}
     * @memberof InsuranceInvoiceDetailsDto
     */
    recordedActivityUnits?: number;
}

export function InsuranceInvoiceDetailsDtoFromJSON(json: any): InsuranceInvoiceDetailsDto {
    return InsuranceInvoiceDetailsDtoFromJSONTyped(json, false);
}

export function InsuranceInvoiceDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceInvoiceDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoiceDate': !exists(json, 'invoiceDate') ? undefined : (new Date(json['invoiceDate'])),
        'paymentDate': !exists(json, 'paymentDate') ? undefined : (new Date(json['paymentDate'])),
        'periodStart': !exists(json, 'periodStart') ? undefined : (new Date(json['periodStart'])),
        'periodEnd': !exists(json, 'periodEnd') ? undefined : (new Date(json['periodEnd'])),
        'invoiceAmount': !exists(json, 'invoiceAmount') ? undefined : json['invoiceAmount'],
        'baseAmount': !exists(json, 'baseAmount') ? undefined : json['baseAmount'],
        'payPerUseAmount': !exists(json, 'payPerUseAmount') ? undefined : json['payPerUseAmount'],
        'billedActivityUnits': !exists(json, 'billedActivityUnits') ? undefined : json['billedActivityUnits'],
        'recordedActivityUnits': !exists(json, 'recordedActivityUnits') ? undefined : json['recordedActivityUnits'],
    };
}

export function InsuranceInvoiceDetailsDtoToJSON(value?: InsuranceInvoiceDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoiceDate': value.invoiceDate === undefined ? undefined : (value.invoiceDate.toISOString().substr(0,10)),
        'paymentDate': value.paymentDate === undefined ? undefined : (value.paymentDate.toISOString().substr(0,10)),
        'periodStart': value.periodStart === undefined ? undefined : (value.periodStart.toISOString()),
        'periodEnd': value.periodEnd === undefined ? undefined : (value.periodEnd.toISOString()),
        'invoiceAmount': value.invoiceAmount,
        'baseAmount': value.baseAmount,
        'payPerUseAmount': value.payPerUseAmount,
        'billedActivityUnits': value.billedActivityUnits,
        'recordedActivityUnits': value.recordedActivityUnits,
    };
}

