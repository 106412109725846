/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsuranceDetailsDto
 */
export interface InsuranceDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof InsuranceDetailsDto
     */
    type?: string;
    /**
     * 
     * @type {Date}
     * @memberof InsuranceDetailsDto
     */
    startTimestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InsuranceDetailsDto
     */
    endTimestamp?: Date;
    /**
     * 
     * @type {number}
     * @memberof InsuranceDetailsDto
     */
    insuredAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InsuranceDetailsDto
     */
    excessAmount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsuranceDetailsDto
     */
    insuredRisks?: Array<string>;
}

export function InsuranceDetailsDtoFromJSON(json: any): InsuranceDetailsDto {
    return InsuranceDetailsDtoFromJSONTyped(json, false);
}

export function InsuranceDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'startTimestamp': !exists(json, 'startTimestamp') ? undefined : (new Date(json['startTimestamp'])),
        'endTimestamp': !exists(json, 'endTimestamp') ? undefined : (new Date(json['endTimestamp'])),
        'insuredAmount': !exists(json, 'insuredAmount') ? undefined : json['insuredAmount'],
        'excessAmount': !exists(json, 'excessAmount') ? undefined : json['excessAmount'],
        'insuredRisks': !exists(json, 'insuredRisks') ? undefined : json['insuredRisks'],
    };
}

export function InsuranceDetailsDtoToJSON(value?: InsuranceDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'startTimestamp': value.startTimestamp === undefined ? undefined : (value.startTimestamp.toISOString()),
        'endTimestamp': value.endTimestamp === undefined ? undefined : (value.endTimestamp.toISOString()),
        'insuredAmount': value.insuredAmount,
        'excessAmount': value.excessAmount,
        'insuredRisks': value.insuredRisks,
    };
}

