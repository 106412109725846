/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsurancePayPerUseDetailsDto
 */
export interface InsurancePayPerUseDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof InsurancePayPerUseDetailsDto
     */
    annualExpectedAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InsurancePayPerUseDetailsDto
     */
    annualBaseAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InsurancePayPerUseDetailsDto
     */
    annualCapAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InsurancePayPerUseDetailsDto
     */
    annualExpectedActivity?: number;
    /**
     * 
     * @type {number}
     * @memberof InsurancePayPerUseDetailsDto
     */
    pricePerUnit?: number;
}

export function InsurancePayPerUseDetailsDtoFromJSON(json: any): InsurancePayPerUseDetailsDto {
    return InsurancePayPerUseDetailsDtoFromJSONTyped(json, false);
}

export function InsurancePayPerUseDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsurancePayPerUseDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annualExpectedAmount': !exists(json, 'annualExpectedAmount') ? undefined : json['annualExpectedAmount'],
        'annualBaseAmount': !exists(json, 'annualBaseAmount') ? undefined : json['annualBaseAmount'],
        'annualCapAmount': !exists(json, 'annualCapAmount') ? undefined : json['annualCapAmount'],
        'annualExpectedActivity': !exists(json, 'annualExpectedActivity') ? undefined : json['annualExpectedActivity'],
        'pricePerUnit': !exists(json, 'pricePerUnit') ? undefined : json['pricePerUnit'],
    };
}

export function InsurancePayPerUseDetailsDtoToJSON(value?: InsurancePayPerUseDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annualExpectedAmount': value.annualExpectedAmount,
        'annualBaseAmount': value.annualBaseAmount,
        'annualCapAmount': value.annualCapAmount,
        'annualExpectedActivity': value.annualExpectedActivity,
        'pricePerUnit': value.pricePerUnit,
    };
}

