/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Status of the payment.
 * @export
 */
export const PaymentStatusEnum = {
    Initiated: 'INITIATED',
    Pending: 'PENDING',
    Confirmed: 'CONFIRMED'
} as const;
export type PaymentStatusEnum = typeof PaymentStatusEnum[keyof typeof PaymentStatusEnum];


export function PaymentStatusEnumFromJSON(json: any): PaymentStatusEnum {
    return PaymentStatusEnumFromJSONTyped(json, false);
}

export function PaymentStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentStatusEnum {
    return json as PaymentStatusEnum;
}

export function PaymentStatusEnumToJSON(value?: PaymentStatusEnum | null): any {
    return value as any;
}

