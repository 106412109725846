/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InsurancePaymentIntervalEnum = {
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    Annual: 'ANNUAL',
    Threshold: 'THRESHOLD'
} as const;
export type InsurancePaymentIntervalEnum = typeof InsurancePaymentIntervalEnum[keyof typeof InsurancePaymentIntervalEnum];


export function InsurancePaymentIntervalEnumFromJSON(json: any): InsurancePaymentIntervalEnum {
    return InsurancePaymentIntervalEnumFromJSONTyped(json, false);
}

export function InsurancePaymentIntervalEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsurancePaymentIntervalEnum {
    return json as InsurancePaymentIntervalEnum;
}

export function InsurancePaymentIntervalEnumToJSON(value?: InsurancePaymentIntervalEnum | null): any {
    return value as any;
}

