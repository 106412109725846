/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorInfo,
    ErrorInfoFromJSON,
    ErrorInfoToJSON,
    MachineActivityDto,
    MachineActivityDtoFromJSON,
    MachineActivityDtoToJSON,
    MachineActivityRequest,
    MachineActivityRequestFromJSON,
    MachineActivityRequestToJSON,
    MachineDto,
    MachineDtoFromJSON,
    MachineDtoToJSON,
    MachineRequest,
    MachineRequestFromJSON,
    MachineRequestToJSON,
} from '../models';

export interface AddMachineActivityRequest {
    machineId: string;
    machineActivityRequest: MachineActivityRequest;
}

export interface CreateMachineRequest {
    machineRequest: MachineRequest;
}

export interface GetMachineRequest {
    machineId: string;
}

export interface GetMachineActivitiesRequest {
    machineId: string;
    activityStatus?: GetMachineActivitiesActivityStatusEnum;
    startTimestamp?: Date;
    endTimestamp?: Date;
}

export interface GetMachineActivitiesCsvRequest {
    machineId: string;
    activityStatus?: GetMachineActivitiesCsvActivityStatusEnum;
    startTimestamp?: Date;
    endTimestamp?: Date;
}

export interface UpdateMachineRequest {
    machineId: string;
    machineRequest: MachineRequest;
}

/**
 * 
 */
export class MachinesApi extends runtime.BaseAPI {

    /**
     * Reports a new machine activity to the specified machine.
     */
    async addMachineActivityRaw(requestParameters: AddMachineActivityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling addMachineActivity.');
        }

        if (requestParameters.machineActivityRequest === null || requestParameters.machineActivityRequest === undefined) {
            throw new runtime.RequiredError('machineActivityRequest','Required parameter requestParameters.machineActivityRequest was null or undefined when calling addMachineActivity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/machines/{machineId}/activities`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineActivityRequestToJSON(requestParameters.machineActivityRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Reports a new machine activity to the specified machine.
     */
    async addMachineActivity(machineId: string, machineActivityRequest: MachineActivityRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.addMachineActivityRaw({ machineId: machineId, machineActivityRequest: machineActivityRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new machine.
     */
    async createMachineRaw(requestParameters: CreateMachineRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.machineRequest === null || requestParameters.machineRequest === undefined) {
            throw new runtime.RequiredError('machineRequest','Required parameter requestParameters.machineRequest was null or undefined when calling createMachine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/machines`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineRequestToJSON(requestParameters.machineRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a new machine.
     */
    async createMachine(machineRequest: MachineRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.createMachineRaw({ machineRequest: machineRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a specific machine.
     */
    async getMachineRaw(requestParameters: GetMachineRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MachineDto>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling getMachine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/machines/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDtoFromJSON(jsonValue));
    }

    /**
     * Returns a specific machine.
     */
    async getMachine(machineId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MachineDto> {
        const response = await this.getMachineRaw({ machineId: machineId }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve recorded activities for the specified machine.
     */
    async getMachineActivitiesRaw(requestParameters: GetMachineActivitiesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MachineActivityDto>>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling getMachineActivities.');
        }

        const queryParameters: any = {};

        if (requestParameters.activityStatus !== undefined) {
            queryParameters['activityStatus'] = requestParameters.activityStatus;
        }

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['startTimestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['endTimestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/machines/{machineId}/activities`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineActivityDtoFromJSON));
    }

    /**
     * Retrieve recorded activities for the specified machine.
     */
    async getMachineActivities(machineId: string, activityStatus?: GetMachineActivitiesActivityStatusEnum, startTimestamp?: Date, endTimestamp?: Date, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MachineActivityDto>> {
        const response = await this.getMachineActivitiesRaw({ machineId: machineId, activityStatus: activityStatus, startTimestamp: startTimestamp, endTimestamp: endTimestamp }, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve recorded activities for the specified machine as CSV.
     */
    async getMachineActivitiesCsvRaw(requestParameters: GetMachineActivitiesCsvRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling getMachineActivitiesCsv.');
        }

        const queryParameters: any = {};

        if (requestParameters.activityStatus !== undefined) {
            queryParameters['activityStatus'] = requestParameters.activityStatus;
        }

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['startTimestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['endTimestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/machines/{machineId}/activities/csv`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Retrieve recorded activities for the specified machine as CSV.
     */
    async getMachineActivitiesCsv(machineId: string, activityStatus?: GetMachineActivitiesCsvActivityStatusEnum, startTimestamp?: Date, endTimestamp?: Date, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<string> {
        const response = await this.getMachineActivitiesCsvRaw({ machineId: machineId, activityStatus: activityStatus, startTimestamp: startTimestamp, endTimestamp: endTimestamp }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of all machines.
     */
    async getMachinesRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MachineDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/machines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineDtoFromJSON));
    }

    /**
     * Returns a list of all machines.
     */
    async getMachines(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MachineDto>> {
        const response = await this.getMachinesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates a specific machine.
     */
    async updateMachineRaw(requestParameters: UpdateMachineRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling updateMachine.');
        }

        if (requestParameters.machineRequest === null || requestParameters.machineRequest === undefined) {
            throw new runtime.RequiredError('machineRequest','Required parameter requestParameters.machineRequest was null or undefined when calling updateMachine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/machines/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MachineRequestToJSON(requestParameters.machineRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Updates a specific machine.
     */
    async updateMachine(machineId: string, machineRequest: MachineRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.updateMachineRaw({ machineId: machineId, machineRequest: machineRequest }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetMachineActivitiesActivityStatusEnum = {
    All: 'ALL',
    Unpaid: 'UNPAID',
    Paid: 'PAID'
} as const;
export type GetMachineActivitiesActivityStatusEnum = typeof GetMachineActivitiesActivityStatusEnum[keyof typeof GetMachineActivitiesActivityStatusEnum];
/**
 * @export
 */
export const GetMachineActivitiesCsvActivityStatusEnum = {
    All: 'ALL',
    Unpaid: 'UNPAID',
    Paid: 'PAID'
} as const;
export type GetMachineActivitiesCsvActivityStatusEnum = typeof GetMachineActivitiesCsvActivityStatusEnum[keyof typeof GetMachineActivitiesCsvActivityStatusEnum];
