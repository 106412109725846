/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CordaX500NameDto,
    CordaX500NameDtoFromJSON,
    CordaX500NameDtoFromJSONTyped,
    CordaX500NameDtoToJSON,
} from './CordaX500NameDto';

/**
 * 
 * @export
 * @interface NodeDto
 */
export interface NodeDto {
    /**
     * 
     * @type {CordaX500NameDto}
     * @memberof NodeDto
     */
    identity: CordaX500NameDto;
}

export function NodeDtoFromJSON(json: any): NodeDto {
    return NodeDtoFromJSONTyped(json, false);
}

export function NodeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NodeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identity': CordaX500NameDtoFromJSON(json['identity']),
    };
}

export function NodeDtoToJSON(value?: NodeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identity': CordaX500NameDtoToJSON(value.identity),
    };
}

