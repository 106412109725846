/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MachineActivityDtoAllOf,
    MachineActivityDtoAllOfFromJSON,
    MachineActivityDtoAllOfFromJSONTyped,
    MachineActivityDtoAllOfToJSON,
} from './MachineActivityDtoAllOf';
import {
    MachineActivityRequest,
    MachineActivityRequestFromJSON,
    MachineActivityRequestFromJSONTyped,
    MachineActivityRequestToJSON,
} from './MachineActivityRequest';
import {
    MachineActivityStatusEnum,
    MachineActivityStatusEnumFromJSON,
    MachineActivityStatusEnumFromJSONTyped,
    MachineActivityStatusEnumToJSON,
} from './MachineActivityStatusEnum';

/**
 * 
 * @export
 * @interface MachineActivityDto
 */
export interface MachineActivityDto {
    /**
     * Timestamp when the activity was recorded.
     * @type {Date}
     * @memberof MachineActivityDto
     */
    activityTimestamp: Date;
    /**
     * Unit price in EUR.
     * @type {number}
     * @memberof MachineActivityDto
     */
    pricePerUnit: number;
    /**
     * Price in EUR for the recoded machine activity.
     * @type {number}
     * @memberof MachineActivityDto
     */
    activityCost: number;
    /**
     * 
     * @type {MachineActivityStatusEnum}
     * @memberof MachineActivityDto
     */
    activityStatus: MachineActivityStatusEnum;
    /**
     * Number of chargeable machine units.
     * @type {number}
     * @memberof MachineActivityDto
     */
    activityUnits: number;
    /**
     * Optional note for the recorded machine activity.
     * @type {string}
     * @memberof MachineActivityDto
     */
    activityNote?: string;
}

export function MachineActivityDtoFromJSON(json: any): MachineActivityDto {
    return MachineActivityDtoFromJSONTyped(json, false);
}

export function MachineActivityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineActivityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activityTimestamp': (new Date(json['activityTimestamp'])),
        'pricePerUnit': json['pricePerUnit'],
        'activityCost': json['activityCost'],
        'activityStatus': MachineActivityStatusEnumFromJSON(json['activityStatus']),
        'activityUnits': json['activityUnits'],
        'activityNote': !exists(json, 'activityNote') ? undefined : json['activityNote'],
    };
}

export function MachineActivityDtoToJSON(value?: MachineActivityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activityTimestamp': (value.activityTimestamp.toISOString()),
        'pricePerUnit': value.pricePerUnit,
        'activityCost': value.activityCost,
        'activityStatus': MachineActivityStatusEnumToJSON(value.activityStatus),
        'activityUnits': value.activityUnits,
        'activityNote': value.activityNote,
    };
}

