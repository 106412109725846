/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InsuranceInvoiceStatusEnum = {
    Pending: 'PENDING',
    Billed: 'BILLED',
    Paid: 'PAID',
    Canceled: 'CANCELED'
} as const;
export type InsuranceInvoiceStatusEnum = typeof InsuranceInvoiceStatusEnum[keyof typeof InsuranceInvoiceStatusEnum];


export function InsuranceInvoiceStatusEnumFromJSON(json: any): InsuranceInvoiceStatusEnum {
    return InsuranceInvoiceStatusEnumFromJSONTyped(json, false);
}

export function InsuranceInvoiceStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceInvoiceStatusEnum {
    return json as InsuranceInvoiceStatusEnum;
}

export function InsuranceInvoiceStatusEnumToJSON(value?: InsuranceInvoiceStatusEnum | null): any {
    return value as any;
}

