/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorInfo,
    ErrorInfoFromJSON,
    ErrorInfoToJSON,
    MembershipDto,
    MembershipDtoFromJSON,
    MembershipDtoToJSON,
    MembershipRolesRequest,
    MembershipRolesRequestFromJSON,
    MembershipRolesRequestToJSON,
    MembershipStatusRequest,
    MembershipStatusRequestFromJSON,
    MembershipStatusRequestToJSON,
} from '../models';

export interface GetMembershipRequest {
    membershipId: string;
}

export interface UpdateMembershipRolesRequest {
    membershipId: string;
    membershipRolesRequest?: MembershipRolesRequest;
}

export interface UpdateMembershipStatusRequest {
    membershipId: string;
    membershipStatusRequest?: MembershipStatusRequest;
}

/**
 * 
 */
export class MembershipsApi extends runtime.BaseAPI {

    /**
     * Create the business network.
     */
    async createBusinessNetworkRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/memberships/create-business-network`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create the business network.
     */
    async createBusinessNetwork(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.createBusinessNetworkRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns a specific membership.
     */
    async getMembershipRaw(requestParameters: GetMembershipRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MembershipDto>> {
        if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
            throw new runtime.RequiredError('membershipId','Required parameter requestParameters.membershipId was null or undefined when calling getMembership.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/memberships/{membershipId}`.replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MembershipDtoFromJSON(jsonValue));
    }

    /**
     * Returns a specific membership.
     */
    async getMembership(membershipId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MembershipDto> {
        const response = await this.getMembershipRaw({ membershipId: membershipId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns all memberships in the Corda business network.
     */
    async getMembershipsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MembershipDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/memberships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MembershipDtoFromJSON));
    }

    /**
     * Returns all memberships in the Corda business network.
     */
    async getMemberships(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MembershipDto>> {
        const response = await this.getMembershipsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Request membership in the Corda business network.
     */
    async requestMembershipRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/memberships/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Request membership in the Corda business network.
     */
    async requestMembership(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.requestMembershipRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update membership roles.
     */
    async updateMembershipRolesRaw(requestParameters: UpdateMembershipRolesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
            throw new runtime.RequiredError('membershipId','Required parameter requestParameters.membershipId was null or undefined when calling updateMembershipRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/memberships/{membershipId}/roles`.replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MembershipRolesRequestToJSON(requestParameters.membershipRolesRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update membership roles.
     */
    async updateMembershipRoles(membershipId: string, membershipRolesRequest?: MembershipRolesRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.updateMembershipRolesRaw({ membershipId: membershipId, membershipRolesRequest: membershipRolesRequest }, initOverrides);
        return await response.value();
    }

    /**
     * Update membership status.
     */
    async updateMembershipStatusRaw(requestParameters: UpdateMembershipStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.membershipId === null || requestParameters.membershipId === undefined) {
            throw new runtime.RequiredError('membershipId','Required parameter requestParameters.membershipId was null or undefined when calling updateMembershipStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/memberships/{membershipId}/status`.replace(`{${"membershipId"}}`, encodeURIComponent(String(requestParameters.membershipId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MembershipStatusRequestToJSON(requestParameters.membershipStatusRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update membership status.
     */
    async updateMembershipStatus(membershipId: string, membershipStatusRequest?: MembershipStatusRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.updateMembershipStatusRaw({ membershipId: membershipId, membershipStatusRequest: membershipStatusRequest }, initOverrides);
        return await response.value();
    }

}
