import Keycloak from 'keycloak-js';
import {
  Configuration,
  ConfigurationParameters,
  InsurancesApi,
  MachinesApi,
  MemberApi,
  MembershipsApi,
  NetworkApi,
  PaymentsApi,
  PaymentConfigurationApi,
} from '../generated';

/**
 * Setup Keycloak instance
 */
const keycloak = Keycloak({
  url: '/auth',
  realm: 'payperchain',
  clientId: 'dashboard',
});

/**
 * Create a Configuration object with a refreshed Bearer token
 */
const getConfiguration = async () => {
  // refresh token if it is not valid for a long time anymore
  // https://www.keycloak.org/docs/latest/securing_apps/index.html#_javascript_adapter
  await keycloak.updateToken(30);

  const params: ConfigurationParameters = {
    basePath: '/api',
    accessToken: 'Bearer ' + keycloak.token,
  };

  return new Configuration(params);
};

export const getNetworkApi = async function (): Promise<NetworkApi> {
  return new NetworkApi(await getConfiguration());
};

export const getMachinesApi = async function (): Promise<MachinesApi> {
  return new MachinesApi(await getConfiguration());
};

export const getMemberApi = async function (): Promise<MemberApi> {
  return new MemberApi(await getConfiguration());
};

export const getMembershipsApi = async function (): Promise<MembershipsApi> {
  return new MembershipsApi(await getConfiguration());
};

export const getPaymentsApi = async function (): Promise<PaymentsApi> {
  return new PaymentsApi(await getConfiguration());
};

export const getInsurancesApi = async function (): Promise<InsurancesApi> {
  return new InsurancesApi(await getConfiguration());
};

export const getPaymentConfigurationApi = async function (): Promise<PaymentConfigurationApi> {
  return new PaymentConfigurationApi(await getConfiguration());
};

export default keycloak;
