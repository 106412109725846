/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsuranceMachineDetailsDto
 */
export interface InsuranceMachineDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof InsuranceMachineDetailsDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceMachineDetailsDto
     */
    manufacturer?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceMachineDetailsDto
     */
    serialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceMachineDetailsDto
     */
    manufacturingDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceMachineDetailsDto
     */
    location?: string;
}

export function InsuranceMachineDetailsDtoFromJSON(json: any): InsuranceMachineDetailsDto {
    return InsuranceMachineDetailsDtoFromJSONTyped(json, false);
}

export function InsuranceMachineDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceMachineDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'manufacturer': !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'manufacturingDate': !exists(json, 'manufacturingDate') ? undefined : json['manufacturingDate'],
        'location': !exists(json, 'location') ? undefined : json['location'],
    };
}

export function InsuranceMachineDetailsDtoToJSON(value?: InsuranceMachineDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'manufacturer': value.manufacturer,
        'serialNumber': value.serialNumber,
        'manufacturingDate': value.manufacturingDate,
        'location': value.location,
    };
}

