/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorInfo,
    ErrorInfoFromJSON,
    ErrorInfoToJSON,
    MemberDto,
    MemberDtoFromJSON,
    MemberDtoToJSON,
    MemberRequest,
    MemberRequestFromJSON,
    MemberRequestToJSON,
} from '../models';

export interface UpdateMemberRequest {
    memberRequest: MemberRequest;
}

/**
 * 
 */
export class MemberApi extends runtime.BaseAPI {

    /**
     * Returns the member information for the active node.
     */
    async getMemberRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<MemberDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/member`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberDtoFromJSON(jsonValue));
    }

    /**
     * Returns the member information for the active node.
     */
    async getMember(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<MemberDto> {
        const response = await this.getMemberRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates the member infomation of the active node.
     */
    async updateMemberRaw(requestParameters: UpdateMemberRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.memberRequest === null || requestParameters.memberRequest === undefined) {
            throw new runtime.RequiredError('memberRequest','Required parameter requestParameters.memberRequest was null or undefined when calling updateMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/member`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MemberRequestToJSON(requestParameters.memberRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Updates the member infomation of the active node.
     */
    async updateMember(memberRequest: MemberRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<boolean> {
        const response = await this.updateMemberRaw({ memberRequest: memberRequest }, initOverrides);
        return await response.value();
    }

}
