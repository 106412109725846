/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorInfo,
    ErrorInfoFromJSON,
    ErrorInfoToJSON,
    NetworkNodesDto,
    NetworkNodesDtoFromJSON,
    NetworkNodesDtoToJSON,
    NodeDto,
    NodeDtoFromJSON,
    NodeDtoToJSON,
} from '../models';

/**
 * 
 */
export class NetworkApi extends runtime.BaseAPI {

    /**
     * Retrieve a DTO for the active node.
     */
    async getActiveNodeRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<NodeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/network/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NodeDtoFromJSON(jsonValue));
    }

    /**
     * Retrieve a DTO for the active node.
     */
    async getActiveNode(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<NodeDto> {
        const response = await this.getActiveNodeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns the node of the current user, a list of all nodes, and a list of the notaries in the network.
     */
    async getNetworkNodesRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<NetworkNodesDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/network/nodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NetworkNodesDtoFromJSON(jsonValue));
    }

    /**
     * Returns the node of the current user, a list of all nodes, and a list of the notaries in the network.
     */
    async getNetworkNodes(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<NetworkNodesDto> {
        const response = await this.getNetworkNodesRaw(initOverrides);
        return await response.value();
    }

}
