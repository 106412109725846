/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentSummaryDto,
    PaymentSummaryDtoFromJSON,
    PaymentSummaryDtoFromJSONTyped,
    PaymentSummaryDtoToJSON,
} from './PaymentSummaryDto';

/**
 * 
 * @export
 * @interface WalletDto
 */
export interface WalletDto {
    /**
     * A list of machine payment summaries.
     * @type {Array<PaymentSummaryDto>}
     * @memberof WalletDto
     */
    machinePaymentSummaries: Array<PaymentSummaryDto>;
    /**
     * 
     * @type {PaymentSummaryDto}
     * @memberof WalletDto
     */
    totalPaymentSummary: PaymentSummaryDto;
    /**
     * Total balance for all payments and machines.
     * @type {number}
     * @memberof WalletDto
     */
    accountBalance: number;
}

export function WalletDtoFromJSON(json: any): WalletDto {
    return WalletDtoFromJSONTyped(json, false);
}

export function WalletDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WalletDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'machinePaymentSummaries': ((json['machinePaymentSummaries'] as Array<any>).map(PaymentSummaryDtoFromJSON)),
        'totalPaymentSummary': PaymentSummaryDtoFromJSON(json['totalPaymentSummary']),
        'accountBalance': json['accountBalance'],
    };
}

export function WalletDtoToJSON(value?: WalletDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'machinePaymentSummaries': ((value.machinePaymentSummaries as Array<any>).map(PaymentSummaryDtoToJSON)),
        'totalPaymentSummary': PaymentSummaryDtoToJSON(value.totalPaymentSummary),
        'accountBalance': value.accountBalance,
    };
}

