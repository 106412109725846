import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialTable, { Column } from 'material-table';
import { CircularProgress, Paper } from '@material-ui/core';

import ColorChip from './ColorChip';
import { formatDateTime } from '../../common/format';
import { MaterialTableIcons } from './MaterialTableIcons';
import { NumberFormatEurText } from './numberformat/NumberFormatEur';
import { NumberFormatUnitsText } from './numberformat/NumberFormatUnits';
import { MachineActivityDto, MachineActivityStatusEnum } from '../../generated';

export function MachineActivityTable(props: {
  machineActivities: MachineActivityDto[];
  promiseInProgress: boolean;
}): JSX.Element {
  // Trnaslations
  const { t } = useTranslation();
  const rowTxt = t('row');
  const paidTxt = t('paid');
  const unpaidTxt = t('unpaid');
  const containsTxt = t('contains');
  const pricePerUnitTxt = t('pricePerUnit');
  const noRecordsToDisplayTxt = t('noRecordsToDisplay');
  const activityCostTxt = t('machineActivityTable.activityCost');
  const activityNoteTxt = t('machineActivityTable.activityNote');
  const activityUnitsTxt = t('machineActivityTable.activityUnits');
  const activityStatusTxt = t('machineActivityTable.activityStatus');
  const activityTimestampTxt = t('machineActivityTable.activityTimestamp');

  const ColumnActivityTimestamp: Column<MachineActivityDto> = {
    title: activityTimestampTxt,
    field: 'activityTimestamp',
    type: 'datetime',
    render: (data: MachineActivityDto) => formatDateTime(data.activityTimestamp),
    defaultSort: 'desc',
  };

  const ColumnActivityUnits: Column<MachineActivityDto> = {
    title: activityUnitsTxt,
    field: 'activityUnits',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: MachineActivityDto) => <NumberFormatUnitsText value={data.activityUnits} />,
  };

  const ColumnActivityPricePerUnit: Column<MachineActivityDto> = {
    title: pricePerUnitTxt,
    field: 'pricePerUnit',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: MachineActivityDto) => <NumberFormatEurText value={data.pricePerUnit} />,
  };

  const ColumnActivityCost: Column<MachineActivityDto> = {
    title: activityCostTxt,
    field: 'activityCost',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' },
    render: (data: MachineActivityDto) => <NumberFormatEurText value={data.activityCost} />,
  };

  const ColumnActivityStatus: Column<MachineActivityDto> = {
    title: activityStatusTxt,
    field: 'activityStatus',
    headerStyle: { textAlign: 'center' },
    cellStyle: { textAlign: 'center' },
    render: (data: MachineActivityDto) => {
      return <MachineActivityStatusChip activityStatus={data.activityStatus} />;
    },
  };

  const ColumnActivityNote: Column<MachineActivityDto> = {
    title: activityNoteTxt,
    field: 'activityNote',
    filterPlaceholder: containsTxt,
  };

  const detailColumnDefs: Column<MachineActivityDto>[] = [
    ColumnActivityTimestamp,
    ColumnActivityUnits,
    ColumnActivityPricePerUnit,
    ColumnActivityCost,
    ColumnActivityStatus,
    ColumnActivityNote,
  ];

  function MachineActivityStatusChip(props: { activityStatus: MachineActivityStatusEnum }) {
    let chipColor: 'green' | 'orange' = 'green';
    let activityStatusLabel = '';

    switch (props.activityStatus) {
      case MachineActivityStatusEnum.Paid: {
        chipColor = 'green';
        activityStatusLabel = paidTxt;
        break;
      }
      case MachineActivityStatusEnum.Unpaid: {
        chipColor = 'orange';
        activityStatusLabel = unpaidTxt;
        break;
      }
    }

    return <ColorChip chipColor={chipColor} size="small" label={activityStatusLabel} />;
  }

  return (
    <MaterialTable
      localization={{
        pagination: {
          labelRowsSelect: rowTxt,
        },
        body: {
          emptyDataSourceMessage: props.promiseInProgress ? <CircularProgress /> : noRecordsToDisplayTxt,
        },
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
        Toolbar: () => <div />,
      }}
      icons={MaterialTableIcons()}
      columns={detailColumnDefs}
      data={props.machineActivities}
      options={{
        filtering: false,
        sorting: true,
        showTitle: false,
        paging: false,
        search: false,
      }}
    />
  );
}
