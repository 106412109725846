/**
 * This class holds all external configurations that can be set via the /public/config.js file.
 * Default values have to be set in /public/config.js file via the window object.
 */
export default class ExternalConfig {
  // @ts-ignore
  static REACT_APP_PLATFORM_VERSION: string = window.REACT_APP_PLATFORM_VERSION;
  // @ts-ignore
  static REACT_APP_PROFILE: string = window.REACT_APP_PROFILE;
  // @ts-ignore
  static REACT_APP_TITLE: string = window.REACT_APP_TITLE;
}
