/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentInformationDto,
    PaymentInformationDtoFromJSON,
    PaymentInformationDtoFromJSONTyped,
    PaymentInformationDtoToJSON,
} from './PaymentInformationDto';

/**
 * 
 * @export
 * @interface MemberRequest
 */
export interface MemberRequest {
    /**
     * 
     * @type {PaymentInformationDto}
     * @memberof MemberRequest
     */
    paymentInformation: PaymentInformationDto;
    /**
     * 
     * @type {Array<PaymentInformationDto>}
     * @memberof MemberRequest
     */
    extraPaymentInformation?: Array<PaymentInformationDto>;
}

export function MemberRequestFromJSON(json: any): MemberRequest {
    return MemberRequestFromJSONTyped(json, false);
}

export function MemberRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentInformation': PaymentInformationDtoFromJSON(json['paymentInformation']),
        'extraPaymentInformation': !exists(json, 'extraPaymentInformation') ? undefined : ((json['extraPaymentInformation'] as Array<any>).map(PaymentInformationDtoFromJSON)),
    };
}

export function MemberRequestToJSON(value?: MemberRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentInformation': PaymentInformationDtoToJSON(value.paymentInformation),
        'extraPaymentInformation': value.extraPaymentInformation === undefined ? undefined : ((value.extraPaymentInformation as Array<any>).map(PaymentInformationDtoToJSON)),
    };
}

