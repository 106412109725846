import React from 'react';
import { TableCell } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';

export const DetailsEntry: React.FunctionComponent<{ property: string }> = function (props) {
  const cellStyle: React.CSSProperties = {
    borderBottom: 0,
    verticalAlign: 'top',
  };

  return (
    <TableRow>
      <TableCell style={cellStyle}>{props.property + ':'}</TableCell>
      <TableCell style={cellStyle}>{props.children}</TableCell>
    </TableRow>
  );
};
