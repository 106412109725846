import React from 'react';
import get from 'lodash/get';
import { UseFormikType } from './useFormikType';
import { FormControl, FormControlProps, InputLabel, Select, SelectProps } from '@material-ui/core';

// Extend <FormControlProps> property type with "formik"
type FormikFormControlProps = FormControlProps & {
  formik: UseFormikType;
  label: string;
  multiple?: boolean;
  renderValue?: SelectProps['renderValue'];
};

/**
 * Wrapper of <FormControl> with an InputLabel and Select to create a dropdown input for a form
 * @param props FormControl props extended by `label` for label description and `multiple` to create a multi-select dropdown
 */
export default function FormikFormControlSelect(props: FormikFormControlProps): JSX.Element {
  const { formik, id, label, multiple, children, renderValue, ...other } = props;

  if (!id) throw new Error('FormikFormControlSelect "id" property must not be empty');

  return (
    <FormControl id={id} {...other}>
      <InputLabel id={id + '-label'}>{label}</InputLabel>
      <Select
        labelId={id + '-label'}
        id={id + '-select'}
        value={get(formik.values, id, multiple ? [] : '')}
        onChange={formik.handleChange(id)}
        label={label}
        multiple={multiple}
        renderValue={renderValue}
      >
        {children}
      </Select>
    </FormControl>
  );
}
