/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsuranceInvoiceCreateRequestAllOf,
    InsuranceInvoiceCreateRequestAllOfFromJSON,
    InsuranceInvoiceCreateRequestAllOfFromJSONTyped,
    InsuranceInvoiceCreateRequestAllOfToJSON,
} from './InsuranceInvoiceCreateRequestAllOf';
import {
    InsuranceInvoiceDetailsDto,
    InsuranceInvoiceDetailsDtoFromJSON,
    InsuranceInvoiceDetailsDtoFromJSONTyped,
    InsuranceInvoiceDetailsDtoToJSON,
} from './InsuranceInvoiceDetailsDto';
import {
    InsuranceInvoiceStatusEnum,
    InsuranceInvoiceStatusEnumFromJSON,
    InsuranceInvoiceStatusEnumFromJSONTyped,
    InsuranceInvoiceStatusEnumToJSON,
} from './InsuranceInvoiceStatusEnum';
import {
    InsuranceInvoiceUpdateRequest,
    InsuranceInvoiceUpdateRequestFromJSON,
    InsuranceInvoiceUpdateRequestFromJSONTyped,
    InsuranceInvoiceUpdateRequestToJSON,
} from './InsuranceInvoiceUpdateRequest';

/**
 * 
 * @export
 * @interface InsuranceInvoiceCreateRequest
 */
export interface InsuranceInvoiceCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof InsuranceInvoiceCreateRequest
     */
    insuranceExternalId: string;
    /**
     * 
     * @type {InsuranceInvoiceStatusEnum}
     * @memberof InsuranceInvoiceCreateRequest
     */
    invoiceStatus: InsuranceInvoiceStatusEnum;
    /**
     * 
     * @type {InsuranceInvoiceDetailsDto}
     * @memberof InsuranceInvoiceCreateRequest
     */
    invoiceDetails: InsuranceInvoiceDetailsDto;
}

export function InsuranceInvoiceCreateRequestFromJSON(json: any): InsuranceInvoiceCreateRequest {
    return InsuranceInvoiceCreateRequestFromJSONTyped(json, false);
}

export function InsuranceInvoiceCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceInvoiceCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'insuranceExternalId': json['insuranceExternalId'],
        'invoiceStatus': InsuranceInvoiceStatusEnumFromJSON(json['invoiceStatus']),
        'invoiceDetails': InsuranceInvoiceDetailsDtoFromJSON(json['invoiceDetails']),
    };
}

export function InsuranceInvoiceCreateRequestToJSON(value?: InsuranceInvoiceCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'insuranceExternalId': value.insuranceExternalId,
        'invoiceStatus': InsuranceInvoiceStatusEnumToJSON(value.invoiceStatus),
        'invoiceDetails': InsuranceInvoiceDetailsDtoToJSON(value.invoiceDetails),
    };
}

