import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Paper } from '@material-ui/core';

import { InsuranceInvoiceDto } from '../../../../generated';
import { DetailsBlock } from '../../../assets/details/DetailsBlock';
import { DetailsEntry } from '../../../assets/details/DetailsEntry';
import { NumberFormatUnitsText } from '../../../assets/numberformat/NumberFormatUnits';
import { formatDate, formatDateTime } from '../../../../common/format';
import { useStyles } from '../../../Styles';

export function InsuranceInvoiceDetails(props: { insuranceInvoice: InsuranceInvoiceDto }): JSX.Element {
  const classes = useStyles();

  // Trnaslations
  const { t } = useTranslation();
  const createdTxt = t('created');
  const updatedTxt = t('updated');
  const timestampsTxt = t('timestamps');
  const invoiceDateTxt = t('insurance.invoiceDate');
  const paymentDateTxt = t('insurance.paymentDate');
  const invoiceDetailsTxt = t('insurance.invoiceDetails');
  const billedActivityUnitsTxt = t('insurance.billedActivityUnits');
  const recordedActivityUnitsTxt = t('insurance.recordedActivityUnits');

  return (
    <div className={classes.divTable}>
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Paper className={classes.papertable}>
            <Grid container direction="row" spacing={4}>
              <Grid item md={6} xs={12}>
                <DetailsBlock title={invoiceDetailsTxt}>
                  <DetailsEntry property={invoiceDateTxt}>
                    {formatDate(props.insuranceInvoice.invoiceDetails.invoiceDate)}
                  </DetailsEntry>
                  <DetailsEntry property={paymentDateTxt}>
                    {formatDate(props.insuranceInvoice.invoiceDetails.paymentDate)}
                  </DetailsEntry>
                  <DetailsEntry property={billedActivityUnitsTxt}>
                    <NumberFormatUnitsText value={props.insuranceInvoice.invoiceDetails.billedActivityUnits} />
                  </DetailsEntry>
                  <DetailsEntry property={recordedActivityUnitsTxt}>
                    <NumberFormatUnitsText value={props.insuranceInvoice.invoiceDetails.recordedActivityUnits} />
                  </DetailsEntry>
                </DetailsBlock>
              </Grid>
              <Grid item md={6} xs={12}>
                <DetailsBlock title={timestampsTxt}>
                  <DetailsEntry property={createdTxt}>{formatDateTime(props.insuranceInvoice.created)}</DetailsEntry>
                  <DetailsEntry property={updatedTxt}>{formatDateTime(props.insuranceInvoice.updated)}</DetailsEntry>
                </DetailsBlock>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
