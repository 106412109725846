/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsurancePaymentIntervalEnum,
    InsurancePaymentIntervalEnumFromJSON,
    InsurancePaymentIntervalEnumFromJSONTyped,
    InsurancePaymentIntervalEnumToJSON,
} from './InsurancePaymentIntervalEnum';

/**
 * 
 * @export
 * @interface InsurancePaymentDetailsDto
 */
export interface InsurancePaymentDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof InsurancePaymentDetailsDto
     */
    method?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurancePaymentDetailsDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof InsurancePaymentDetailsDto
     */
    basis?: string;
    /**
     * 
     * @type {InsurancePaymentIntervalEnum}
     * @memberof InsurancePaymentDetailsDto
     */
    interval?: InsurancePaymentIntervalEnum;
}

export function InsurancePaymentDetailsDtoFromJSON(json: any): InsurancePaymentDetailsDto {
    return InsurancePaymentDetailsDtoFromJSONTyped(json, false);
}

export function InsurancePaymentDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsurancePaymentDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'method': !exists(json, 'method') ? undefined : json['method'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'basis': !exists(json, 'basis') ? undefined : json['basis'],
        'interval': !exists(json, 'interval') ? undefined : InsurancePaymentIntervalEnumFromJSON(json['interval']),
    };
}

export function InsurancePaymentDetailsDtoToJSON(value?: InsurancePaymentDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'method': value.method,
        'type': value.type,
        'basis': value.basis,
        'interval': InsurancePaymentIntervalEnumToJSON(value.interval),
    };
}

