import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Title } from '../../assets/Title';
import { Cached } from '@material-ui/icons';
import Button from '@material-ui/core/Button';

import { MembershipDto, ResponseError } from '../../../generated';
import useNotifications from '../../assets/useNotifications';
import { getMembershipsApi } from '../../../common/keycloak';
import { useConfig } from '../../../common/config';
import { MembersTable } from './MembersTable';
import MemberDialog from './MemberDialog';
import { useStyles } from '../../Styles';

/**
 * Definition of the component that displays all memberships and their status
 */
export function MembersPage(): JSX.Element {
  const classes = useStyles();
  const { promiseInProgress } = usePromiseTracker({ area: 'memberships', delay: 200 });
  const { showError } = useNotifications();
  const [memberships, setMemberships] = useState(null as never as MembershipDto[]);
  const { machinesPage } = useConfig();

  interface EditMembershipDialogData {
    open: boolean;
    membership?: MembershipDto;
  }
  const [editMembershipDialog, setEditMembershipDialog] = React.useState({
    open: false,
  } as EditMembershipDialogData);

  const [reloadData, setReloadData] = useState(0);
  const reload = () => {
    setReloadData(reloadData + 1);
  };

  // Trnaslations
  const { t } = useTranslation();
  const reloadTxt = t('reload');
  const membersTxt = t('members');
  const userUnauthorizedTxt = t('userUnauthorized');
  const errorFetchingDataTxt = t('errorFetchingData');

  /** fetch data */
  useEffect(() => {
    const loadMembershipData = async () => {
      const api = await getMembershipsApi();
      try {
        const result = await api.getMemberships();
        setMemberships(result);
      } catch (error) {
        if (error instanceof ResponseError) {
          if (error.response && error.response.status === 401) {
            showError(userUnauthorizedTxt);
          } else {
            showError(error.message);
          }
        } else {
          showError(errorFetchingDataTxt);
        }
      }
    };
    trackPromise(loadMembershipData(), 'memberships');
  }, [reloadData, showError]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} style={{ flex: 1 }}>
          <Title>{membersTxt}</Title>
        </Grid>
        <Grid item className={classes.buttonRight}>
          <Button variant="outlined" color="primary" startIcon={<Cached />} onClick={reload}>
            {reloadTxt}
          </Button>
        </Grid>
      </Grid>
      <MembersTable
        memberships={memberships}
        promiseInProgress={promiseInProgress}
        setEditMembershipDialog={setEditMembershipDialog}
        reload={reload}
      />
      {machinesPage.canEditMachines && (
        <MemberDialog
          open={editMembershipDialog.open}
          membership={editMembershipDialog.membership}
          onClose={() => setEditMembershipDialog({ open: false })}
          onUpdate={reload}
        />
      )}
    </React.Fragment>
  );
}
