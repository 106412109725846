/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsuranceInvoiceCreateRequest,
    InsuranceInvoiceCreateRequestFromJSON,
    InsuranceInvoiceCreateRequestFromJSONTyped,
    InsuranceInvoiceCreateRequestToJSON,
} from './InsuranceInvoiceCreateRequest';
import {
    InsuranceInvoiceDetailsDto,
    InsuranceInvoiceDetailsDtoFromJSON,
    InsuranceInvoiceDetailsDtoFromJSONTyped,
    InsuranceInvoiceDetailsDtoToJSON,
} from './InsuranceInvoiceDetailsDto';
import {
    InsuranceInvoiceDtoAllOf,
    InsuranceInvoiceDtoAllOfFromJSON,
    InsuranceInvoiceDtoAllOfFromJSONTyped,
    InsuranceInvoiceDtoAllOfToJSON,
} from './InsuranceInvoiceDtoAllOf';
import {
    InsuranceInvoiceStatusEnum,
    InsuranceInvoiceStatusEnumFromJSON,
    InsuranceInvoiceStatusEnumFromJSONTyped,
    InsuranceInvoiceStatusEnumToJSON,
} from './InsuranceInvoiceStatusEnum';

/**
 * 
 * @export
 * @interface InsuranceInvoiceDto
 */
export interface InsuranceInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof InsuranceInvoiceDto
     */
    invoiceId: string;
    /**
     * 
     * @type {Date}
     * @memberof InsuranceInvoiceDto
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof InsuranceInvoiceDto
     */
    updated: Date;
    /**
     * 
     * @type {string}
     * @memberof InsuranceInvoiceDto
     */
    insuranceExternalId: string;
    /**
     * 
     * @type {InsuranceInvoiceStatusEnum}
     * @memberof InsuranceInvoiceDto
     */
    invoiceStatus: InsuranceInvoiceStatusEnum;
    /**
     * 
     * @type {InsuranceInvoiceDetailsDto}
     * @memberof InsuranceInvoiceDto
     */
    invoiceDetails: InsuranceInvoiceDetailsDto;
}

export function InsuranceInvoiceDtoFromJSON(json: any): InsuranceInvoiceDto {
    return InsuranceInvoiceDtoFromJSONTyped(json, false);
}

export function InsuranceInvoiceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceInvoiceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoiceId': json['invoiceId'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'insuranceExternalId': json['insuranceExternalId'],
        'invoiceStatus': InsuranceInvoiceStatusEnumFromJSON(json['invoiceStatus']),
        'invoiceDetails': InsuranceInvoiceDetailsDtoFromJSON(json['invoiceDetails']),
    };
}

export function InsuranceInvoiceDtoToJSON(value?: InsuranceInvoiceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoiceId': value.invoiceId,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'insuranceExternalId': value.insuranceExternalId,
        'invoiceStatus': InsuranceInvoiceStatusEnumToJSON(value.invoiceStatus),
        'invoiceDetails': InsuranceInvoiceDetailsDtoToJSON(value.invoiceDetails),
    };
}

