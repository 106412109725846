import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid, LinearProgress, MenuItem } from '@material-ui/core';

import FormikForm from '../../../assets/formik/FormikForm';
import {
  CordaX500NameDto,
  InsuranceCreateRequest,
  InsuranceDto,
  InsuranceStatusEnum,
  InsuranceUpdateRequest,
  MachineDto,
} from '../../../../generated';
import { getMachinesApi, getNetworkApi } from '../../../../common/keycloak';
import { formatCordaX500Name, stringToCordaX500Name } from '../../../../common/format';
import FormikFormControlSelect from '../../../assets/formik/FormikFormControlSelect';
import FormikTextField from '../../../assets/formik/FormikTextField';
import useInsuranceApi from '../useInsuranceApi';

/**
 * JSX Element that is an overlay dialog to edit an insurance
 * @param props
 */
export default function InsuranceBasicDialog(props: {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  insuranceDto?: InsuranceDto;
}): JSX.Element {
  const { promiseInProgress } = usePromiseTracker({ area: 'insurance-dialog', delay: 200 });

  const [insurableMachines, setInsurableMachines] = useState([] as MachineDto[]);

  const { handleCreate, handleUpdate } = useInsuranceApi();

  // Translations
  const { t } = useTranslation();
  const none = t('none');
  const insuranceEdit = t('edit');
  const insuranceAdd = t('add');
  const closeBtn = t('closeBtn');
  const saveBtn = t('saveBtn');
  const machineIdTxt = t('machineId');
  const insuranceIdExceedChar = t('exceedChar', { field: t('fields.insuranceId') });
  const insuranceIdTxt = t('insurance.insuranceId');
  const insuranceTxt = t('insurance.insurance');
  const insuranceNumberTxt = t('insurance.insuranceNumber');
  const counterPartyTxt = t('insurance.counterParty');

  /**
   * Interface to store the current state of the form used by formik
   */
  interface InsuranceBasicDialogType {
    insuranceExternalId: string;
    machineId: string;
    counterparty: string;
    insuranceStatus: string;
  }

  // Workaround to cast a string to an enum in Typescript
  // https://blog.oio.de/2014/02/28/typescript-accessing-enum-values-via-a-string/
  const InsuranceStatusEnumFromString: { [idx: string]: InsuranceStatusEnum } = InsuranceStatusEnum as never;

  const mapToInsuranceUpdateRequest = function (
    insuranceDialogTypeData: InsuranceBasicDialogType
  ): InsuranceUpdateRequest {
    // props.insuranceDto is never null when dialog is updating a state
    const insuranceDto = props.insuranceDto!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return {
      insuranceStatus: InsuranceStatusEnumFromString[insuranceDialogTypeData.insuranceStatus],
      insuranceDetails: insuranceDto.insuranceDetails,
      machineDetails: insuranceDto.machineDetails,
      paymentDetails: insuranceDto.paymentDetails,
      payPerUseDetails: insuranceDto.payPerUseDetails,
    };
  };

  const mapToInsuranceCreateRequest = function (
    insuranceDialogTypeData: InsuranceBasicDialogType
  ): InsuranceCreateRequest {
    return {
      insuranceExternalId: insuranceDialogTypeData.insuranceExternalId,
      machineId: insuranceDialogTypeData.machineId,
      counterparty: stringToCordaX500Name(insuranceDialogTypeData.counterparty),
      insuranceStatus: InsuranceStatusEnumFromString[insuranceDialogTypeData.insuranceStatus],
      insuranceDetails: {},
      machineDetails: {},
      paymentDetails: {},
      payPerUseDetails: {},
    };
  };

  const mapToInsuranceBasicDialogType = function (insuranceDto: InsuranceDto): InsuranceBasicDialogType {
    return {
      insuranceExternalId: insuranceDto.insuranceExternalId,
      machineId: insuranceDto.machineId,
      counterparty: formatCordaX500Name(insuranceDto.counterparty),
      insuranceStatus: mapInsuranceStatusEnumValueToKey(insuranceDto.insuranceStatus) || '',
    };
  };

  /**
   * Function to map the string enum value back to its key
   */
  const mapInsuranceStatusEnumValueToKey = function (value: string): string | null {
    const entries = Object.entries(InsuranceStatusEnum);
    for (const entry of entries) {
      if (entry[1] == value) {
        return entry[0];
      }
    }
    return null;
  };

  const getMachineFromMachineId = function (machineId: string): MachineDto | null {
    for (const machine of insurableMachines) {
      if (machine.machineId == machineId) return machine;
    }
    return null;
  };

  const getCounterpartiesFromMachineId = function (machineId: string): CordaX500NameDto[] {
    const machine = getMachineFromMachineId(machineId);
    if (machine == null) return [];

    return [machine.machineOwner, machine.machineUser];
  };

  const validationSchema = yup.object({
    insuranceExternalId: yup.string().required(insuranceIdTxt).max(128, insuranceIdExceedChar),
  });

  const formik = useFormik({
    initialValues: {} as InsuranceBasicDialogType,
    validationSchema: validationSchema,
    onSubmit: props.insuranceDto
      ? (values) =>
          handleUpdate(props.insuranceDto!.insuranceExternalId, mapToInsuranceUpdateRequest(values), props.onSave) // eslint-disable-line @typescript-eslint/no-non-null-assertion
      : (values) => handleCreate(mapToInsuranceCreateRequest(values), props.onSave),
  });

  // Initialize form
  useEffect(() => {
    if (!props.open) return;
    formik.resetForm();

    const initialize = async function () {
      // Get own identity
      const networkApi = await getNetworkApi();
      const currentNode = await networkApi.getActiveNode();

      // Load machines
      const machinesApi = await getMachinesApi();
      const machines = await machinesApi.getMachines();

      // Set insurableMachines all machines that this insurer can insure
      const filteredMachines = machines.filter((machine) => {
        for (const insurer of machine.insurers) {
          if (
            insurer.organization == currentNode.identity.organization &&
            insurer.country == currentNode.identity.country &&
            insurer.locality == currentNode.identity.locality
          )
            return true;
        }
        return false;
      });
      setInsurableMachines(filteredMachines);

      if (props.insuranceDto) {
        await formik.setValues(mapToInsuranceBasicDialogType(props.insuranceDto));
        await formik.validateForm();
      }
    };

    trackPromise(initialize(), 'insurance-dialog');

    // Adding 'formik' to dependencies creates an infinite loop as formik changes every render
  }, [props.open, props.insuranceDto]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
      {/* Without div the progress bar is not visible when scrollbar is shown */}
      {promiseInProgress && (
        <div>
          <LinearProgress />
        </div>
      )}
      <DialogTitle id="form-dialog-title">{`${
        props.insuranceDto ? insuranceEdit : insuranceAdd
      } ${insuranceTxt}`}</DialogTitle>
      <FormikForm formik={formik}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormikTextField
                formik={formik}
                id="insuranceExternalId"
                variant="outlined"
                fullWidth
                label={insuranceNumberTxt}
                required
                disabled={promiseInProgress || !!props.insuranceDto}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikFormControlSelect
                formik={formik}
                label="Status"
                id="insuranceStatus"
                variant="outlined"
                fullWidth
                required
                disabled={promiseInProgress}
              >
                <MenuItem value="">
                  <em>{none}</em>
                </MenuItem>
                {Object.keys(InsuranceStatusEnum).map((status, index) => (
                  <MenuItem key={index} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </FormikFormControlSelect>
            </Grid>
            <Grid item xs={12}>
              <FormikFormControlSelect
                formik={formik}
                label={machineIdTxt}
                id="machineId"
                variant="outlined"
                fullWidth
                required
                disabled={promiseInProgress || !!props.insuranceDto}
              >
                <MenuItem value="">
                  <em>{none}</em>
                </MenuItem>
                {insurableMachines.map((machine, index) => (
                  <MenuItem key={index} value={machine.machineId}>
                    {machine.machineName + ' (' + machine.machineId + ')'}
                  </MenuItem>
                ))}
              </FormikFormControlSelect>
            </Grid>
            <Grid item xs={12}>
              <FormikFormControlSelect
                formik={formik}
                label={counterPartyTxt}
                id="counterparty"
                variant="outlined"
                fullWidth
                required
                disabled={promiseInProgress || !!props.insuranceDto}
              >
                <MenuItem value="">
                  <em>{none}</em>
                </MenuItem>
                {getCounterpartiesFromMachineId(formik.values.machineId).map((counterparty, index) => (
                  <MenuItem key={index} value={formatCordaX500Name(counterparty)}>
                    {formatCordaX500Name(counterparty)}
                  </MenuItem>
                ))}
              </FormikFormControlSelect>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.onClose}>
            {closeBtn}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={promiseInProgress || !formik.isValid}>
            {saveBtn}
          </Button>
        </DialogActions>
      </FormikForm>
    </Dialog>
  );
}
