/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CordaX500NameDto,
    CordaX500NameDtoFromJSON,
    CordaX500NameDtoFromJSONTyped,
    CordaX500NameDtoToJSON,
} from './CordaX500NameDto';
import {
    MembershipDtoAllOf,
    MembershipDtoAllOfFromJSON,
    MembershipDtoAllOfFromJSONTyped,
    MembershipDtoAllOfToJSON,
} from './MembershipDtoAllOf';
import {
    MembershipRoleEnum,
    MembershipRoleEnumFromJSON,
    MembershipRoleEnumFromJSONTyped,
    MembershipRoleEnumToJSON,
} from './MembershipRoleEnum';
import {
    MembershipRolesRequest,
    MembershipRolesRequestFromJSON,
    MembershipRolesRequestFromJSONTyped,
    MembershipRolesRequestToJSON,
} from './MembershipRolesRequest';
import {
    MembershipStatusEnum,
    MembershipStatusEnumFromJSON,
    MembershipStatusEnumFromJSONTyped,
    MembershipStatusEnumToJSON,
} from './MembershipStatusEnum';
import {
    MembershipStatusRequest,
    MembershipStatusRequestFromJSON,
    MembershipStatusRequestFromJSONTyped,
    MembershipStatusRequestToJSON,
} from './MembershipStatusRequest';

/**
 * 
 * @export
 * @interface MembershipDto
 */
export interface MembershipDto {
    /**
     * Linear ID of the membership state.
     * @type {string}
     * @memberof MembershipDto
     */
    membershipId: string;
    /**
     * Timestamp of the membership state creation.
     * @type {Date}
     * @memberof MembershipDto
     */
    created: Date;
    /**
     * Timestamp of the last update to the membership state.
     * @type {Date}
     * @memberof MembershipDto
     */
    updated: Date;
    /**
     * 
     * @type {CordaX500NameDto}
     * @memberof MembershipDto
     */
    identity: CordaX500NameDto;
    /**
     * 
     * @type {MembershipStatusEnum}
     * @memberof MembershipDto
     */
    membershipStatus: MembershipStatusEnum;
    /**
     * 
     * @type {Array<MembershipRoleEnum>}
     * @memberof MembershipDto
     */
    membershipRoles: Array<MembershipRoleEnum>;
}

export function MembershipDtoFromJSON(json: any): MembershipDto {
    return MembershipDtoFromJSONTyped(json, false);
}

export function MembershipDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MembershipDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'membershipId': json['membershipId'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'identity': CordaX500NameDtoFromJSON(json['identity']),
        'membershipStatus': MembershipStatusEnumFromJSON(json['membershipStatus']),
        'membershipRoles': ((json['membershipRoles'] as Array<any>).map(MembershipRoleEnumFromJSON)),
    };
}

export function MembershipDtoToJSON(value?: MembershipDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'membershipId': value.membershipId,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'identity': CordaX500NameDtoToJSON(value.identity),
        'membershipStatus': MembershipStatusEnumToJSON(value.membershipStatus),
        'membershipRoles': ((value.membershipRoles as Array<any>).map(MembershipRoleEnumToJSON)),
    };
}

