/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MembershipStatusEnum,
    MembershipStatusEnumFromJSON,
    MembershipStatusEnumFromJSONTyped,
    MembershipStatusEnumToJSON,
} from './MembershipStatusEnum';

/**
 * 
 * @export
 * @interface MembershipStatusRequest
 */
export interface MembershipStatusRequest {
    /**
     * 
     * @type {MembershipStatusEnum}
     * @memberof MembershipStatusRequest
     */
    membershipStatus: MembershipStatusEnum;
}

export function MembershipStatusRequestFromJSON(json: any): MembershipStatusRequest {
    return MembershipStatusRequestFromJSONTyped(json, false);
}

export function MembershipStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MembershipStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'membershipStatus': MembershipStatusEnumFromJSON(json['membershipStatus']),
    };
}

export function MembershipStatusRequestToJSON(value?: MembershipStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'membershipStatus': MembershipStatusEnumToJSON(value.membershipStatus),
    };
}

