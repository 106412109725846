/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Status indicating if the activity is paid or not.
 * @export
 */
export const MachineActivityStatusEnum = {
    Unpaid: 'UNPAID',
    Paid: 'PAID'
} as const;
export type MachineActivityStatusEnum = typeof MachineActivityStatusEnum[keyof typeof MachineActivityStatusEnum];


export function MachineActivityStatusEnumFromJSON(json: any): MachineActivityStatusEnum {
    return MachineActivityStatusEnumFromJSONTyped(json, false);
}

export function MachineActivityStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineActivityStatusEnum {
    return json as MachineActivityStatusEnum;
}

export function MachineActivityStatusEnumToJSON(value?: MachineActivityStatusEnum | null): any {
    return value as any;
}

