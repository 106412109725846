/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MembershipRoleEnum,
    MembershipRoleEnumFromJSON,
    MembershipRoleEnumFromJSONTyped,
    MembershipRoleEnumToJSON,
} from './MembershipRoleEnum';

/**
 * 
 * @export
 * @interface MembershipRolesRequest
 */
export interface MembershipRolesRequest {
    /**
     * 
     * @type {Array<MembershipRoleEnum>}
     * @memberof MembershipRolesRequest
     */
    membershipRoles: Array<MembershipRoleEnum>;
}

export function MembershipRolesRequestFromJSON(json: any): MembershipRolesRequest {
    return MembershipRolesRequestFromJSONTyped(json, false);
}

export function MembershipRolesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MembershipRolesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'membershipRoles': ((json['membershipRoles'] as Array<any>).map(MembershipRoleEnumFromJSON)),
    };
}

export function MembershipRolesRequestToJSON(value?: MembershipRolesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'membershipRoles': ((value.membershipRoles as Array<any>).map(MembershipRoleEnumToJSON)),
    };
}

