import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, LinearProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import useInsuranceApi from '../useInsuranceApi';
import FormikForm from '../../../assets/formik/FormikForm';
import FormikTextField from '../../../assets/formik/FormikTextField';
import { InsuranceDto, InsuranceMachineDetailsDto, InsuranceUpdateRequest } from '../../../../generated';

/**
 * JSX Element that is an overlay dialog to edit an insurance
 * @param props
 */
export default function MachineDetailsDialog(props: {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  insuranceDto: InsuranceDto;
}): JSX.Element {
  const { promiseInProgress } = usePromiseTracker({ area: 'insurance-dialog', delay: 200 });

  // Trnaslations
  const { t } = useTranslation();
  const typeTxt = t('type');
  const saveBtn = t('saveBtn');
  const closeBtn = t('closeBtn');
  const locationTxt = t('machineDetailsDialog.location');
  const manufacturerTxt = t('machineDetailsDialog.manufacturer');
  const serialNumberTxt = t('machineDetailsDialog.serialNumber');
  const insuranceDetailsTxt = t('machineDetailsDialog.insuranceDetails');
  const manufacturingDateTxt = t('machineDetailsDialog.manufacturingDate');
  const fieldExceedCharTxt = t('exceedChar', { field: t('fields.field') });

  const { handleUpdate } = useInsuranceApi();

  const mapToInsuranceUpdateRequest = function (
    insuranceMachineDetailsDto: InsuranceMachineDetailsDto
  ): InsuranceUpdateRequest {
    return {
      insuranceStatus: props.insuranceDto.insuranceStatus,
      insuranceDetails: props.insuranceDto.insuranceDetails,
      machineDetails: insuranceMachineDetailsDto,
      paymentDetails: props.insuranceDto.paymentDetails,
      payPerUseDetails: props.insuranceDto.payPerUseDetails,
    };
  };

  const validationSchema = yup.object({
    type: yup.string().max(128, fieldExceedCharTxt),
    manufacturer: yup.string().max(128, fieldExceedCharTxt),
    serialNumber: yup.string().max(128, fieldExceedCharTxt),
    manufacturingDate: yup.string().max(128, fieldExceedCharTxt),
    location: yup.string().max(128, fieldExceedCharTxt),
  });

  const formik = useFormik({
    initialValues: {} as InsuranceMachineDetailsDto,
    validationSchema: validationSchema,
    onSubmit: (values) =>
      handleUpdate(props.insuranceDto.insuranceExternalId, mapToInsuranceUpdateRequest(values), props.onSave),
  });

  // Initialize form
  useEffect(() => {
    if (!props.open) return;
    formik.resetForm();

    const initialize = async function () {
      if (props.insuranceDto) {
        await formik.setValues(props.insuranceDto.machineDetails);
        await formik.validateForm();
      }
    };

    trackPromise(initialize(), 'insurance-dialog');

    // Adding 'formik' to dependencies creates an infinite loop as formik changes every render
  }, [props.open, props.insuranceDto]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
      {/* Without div the progress bar is not visible when scrollbar is shown */}
      {promiseInProgress && (
        <div>
          <LinearProgress />
        </div>
      )}
      <DialogTitle id="form-dialog-title">{insuranceDetailsTxt}</DialogTitle>
      <FormikForm formik={formik}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="type"
                variant="outlined"
                fullWidth
                label={typeTxt}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="manufacturer"
                variant="outlined"
                fullWidth
                label={manufacturerTxt}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="serialNumber"
                variant="outlined"
                fullWidth
                label={serialNumberTxt}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="manufacturingDate"
                variant="outlined"
                fullWidth
                label={manufacturingDateTxt}
                disabled={promiseInProgress}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                id="location"
                variant="outlined"
                fullWidth
                label={locationTxt}
                disabled={promiseInProgress}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.onClose}>
            {closeBtn}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={promiseInProgress || !formik.isValid}>
            {saveBtn}
          </Button>
        </DialogActions>
      </FormikForm>
    </Dialog>
  );
}
