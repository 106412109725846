/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorInfo,
    ErrorInfoFromJSON,
    ErrorInfoToJSON,
    MachineActivityDto,
    MachineActivityDtoFromJSON,
    MachineActivityDtoToJSON,
    PaymentDto,
    PaymentDtoFromJSON,
    PaymentDtoToJSON,
    WalletDto,
    WalletDtoFromJSON,
    WalletDtoToJSON,
} from '../models';

export interface GetMachineActivitiesForPaymentRequest {
    paymentId: string;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     * Retrieve the machine activities for this payment.
     */
    async getMachineActivitiesForPaymentRaw(requestParameters: GetMachineActivitiesForPaymentRequest, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<MachineActivityDto>>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling getMachineActivitiesForPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/payments/{paymentId}/activities`.replace(`{${"paymentId"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineActivityDtoFromJSON));
    }

    /**
     * Retrieve the machine activities for this payment.
     */
    async getMachineActivitiesForPayment(paymentId: string, initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<MachineActivityDto>> {
        const response = await this.getMachineActivitiesForPaymentRaw({ paymentId: paymentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a list of all payments.
     */
    async getPaymentsRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<Array<PaymentDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/payments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentDtoFromJSON));
    }

    /**
     * Returns a list of all payments.
     */
    async getPayments(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<Array<PaymentDto>> {
        const response = await this.getPaymentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns the wallet information.
     */
    async getWalletRaw(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<runtime.ApiResponse<WalletDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/payments/wallet`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WalletDtoFromJSON(jsonValue));
    }

    /**
     * Returns the wallet information.
     */
    async getWallet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<WalletDto> {
        const response = await this.getWalletRaw(initOverrides);
        return await response.value();
    }

}
