/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentConfigurationDTO
 */
export interface PaymentConfigurationDTO {
    /**
     * List of days of the monthly billing run
     * @type {Array<number>}
     * @memberof PaymentConfigurationDTO
     */
    daysOfMonth?: Array<number>;
    /**
     * The type of configuration
     * @type {string}
     * @memberof PaymentConfigurationDTO
     */
    paymentConfigurationType: PaymentConfigurationDTOPaymentConfigurationTypeEnum;
    /**
     * Linear ID of the machine state.
     * @type {string}
     * @memberof PaymentConfigurationDTO
     */
    machineId?: string;
}


/**
 * @export
 */
export const PaymentConfigurationDTOPaymentConfigurationTypeEnum = {
    DayOfMonth: 'DAY_OF_MONTH',
    Threshold: 'THRESHOLD',
    Both: 'BOTH'
} as const;
export type PaymentConfigurationDTOPaymentConfigurationTypeEnum = typeof PaymentConfigurationDTOPaymentConfigurationTypeEnum[keyof typeof PaymentConfigurationDTOPaymentConfigurationTypeEnum];


export function PaymentConfigurationDTOFromJSON(json: any): PaymentConfigurationDTO {
    return PaymentConfigurationDTOFromJSONTyped(json, false);
}

export function PaymentConfigurationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentConfigurationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'daysOfMonth': !exists(json, 'daysOfMonth') ? undefined : json['daysOfMonth'],
        'paymentConfigurationType': json['paymentConfigurationType'],
        'machineId': !exists(json, 'machineId') ? undefined : json['machineId'],
    };
}

export function PaymentConfigurationDTOToJSON(value?: PaymentConfigurationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'daysOfMonth': value.daysOfMonth,
        'paymentConfigurationType': value.paymentConfigurationType,
        'machineId': value.machineId,
    };
}

