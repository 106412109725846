/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentInformationDto
 */
export interface PaymentInformationDto {
    /**
     * The name used in UI selection list
     * @type {string}
     * @memberof PaymentInformationDto
     */
    displayName: string;
    /**
     * Legal organization name as used in the SEPA payment.
     * @type {string}
     * @memberof PaymentInformationDto
     */
    legalName: string;
    /**
     * IBAN of the payment participant.
     * @type {string}
     * @memberof PaymentInformationDto
     */
    iban: string;
    /**
     * BIC of the payment participant.
     * @type {string}
     * @memberof PaymentInformationDto
     */
    bic: string;
}

export function PaymentInformationDtoFromJSON(json: any): PaymentInformationDto {
    return PaymentInformationDtoFromJSONTyped(json, false);
}

export function PaymentInformationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentInformationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': json['displayName'],
        'legalName': json['legalName'],
        'iban': json['iban'],
        'bic': json['bic'],
    };
}

export function PaymentInformationDtoToJSON(value?: PaymentInformationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'legalName': value.legalName,
        'iban': value.iban,
        'bic': value.bic,
    };
}

