/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const InsuranceStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Canceled: 'CANCELED'
} as const;
export type InsuranceStatusEnum = typeof InsuranceStatusEnum[keyof typeof InsuranceStatusEnum];


export function InsuranceStatusEnumFromJSON(json: any): InsuranceStatusEnum {
    return InsuranceStatusEnumFromJSONTyped(json, false);
}

export function InsuranceStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceStatusEnum {
    return json as InsuranceStatusEnum;
}

export function InsuranceStatusEnumToJSON(value?: InsuranceStatusEnum | null): any {
    return value as any;
}

