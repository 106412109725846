import React from 'react';
import get from 'lodash/get';
import { UseFormikType } from './useFormikType';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';

// Extend <KeyboardDatePickerProps> property type with "formik"
type FormikKeyboardDatePickerProps = Omit<Omit<KeyboardDatePickerProps, 'onChange'>, 'value'> & {
  formik: UseFormikType;
  id: string;
};

/**
 * Wrapper of <KeyboardDatePicker> to set value, onChange, error, helperText based on values managed by formik.
 * @param props default <KeyboardDatePicker> properties extended with "formik" parameter
 */
export default function FormikKeyboardDatePicker(props: FormikKeyboardDatePickerProps): JSX.Element {
  const { formik, id, format, ...other } = props;

  if (!id) throw new Error('FormikKeyboardDatePicker "id" property must not be empty');

  return (
    <KeyboardDatePicker
      id={id}
      value={get(formik.values, id, null)}
      onChange={(date) => formik.setFieldValue(id, date)}
      error={get(formik.touched, id, false) && Boolean(get(formik.errors, id))}
      onError={(error) => {
        if (error != null && error !== get(formik.errors, id)) {
          formik.setFieldError(id, error.toString());
        }
      }}
      helperText={get(formik.touched, id, false) && get(formik.errors, id)}
      onFocus={() => {} /* eslint-disable-line @typescript-eslint/no-empty-function */}
      onBlur={(event) => {
        formik.handleBlur(event);
      }}
      format={format ?? 'dd/MM/yyyy'}
      {...other}
    />
  );
}
