/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CordaX500NameDto,
    CordaX500NameDtoFromJSON,
    CordaX500NameDtoFromJSONTyped,
    CordaX500NameDtoToJSON,
} from './CordaX500NameDto';
import {
    MemberDtoAllOf,
    MemberDtoAllOfFromJSON,
    MemberDtoAllOfFromJSONTyped,
    MemberDtoAllOfToJSON,
} from './MemberDtoAllOf';
import {
    MemberRequest,
    MemberRequestFromJSON,
    MemberRequestFromJSONTyped,
    MemberRequestToJSON,
} from './MemberRequest';
import {
    PaymentInformationDto,
    PaymentInformationDtoFromJSON,
    PaymentInformationDtoFromJSONTyped,
    PaymentInformationDtoToJSON,
} from './PaymentInformationDto';

/**
 * 
 * @export
 * @interface MemberDto
 */
export interface MemberDto {
    /**
     * Linear ID of the member state.
     * @type {string}
     * @memberof MemberDto
     */
    memberId: string;
    /**
     * Timestamp of the member state creation.
     * @type {Date}
     * @memberof MemberDto
     */
    created: Date;
    /**
     * Timestamp of the last update to the member state.
     * @type {Date}
     * @memberof MemberDto
     */
    updated: Date;
    /**
     * 
     * @type {CordaX500NameDto}
     * @memberof MemberDto
     */
    identity: CordaX500NameDto;
    /**
     * 
     * @type {PaymentInformationDto}
     * @memberof MemberDto
     */
    paymentInformation: PaymentInformationDto;
    /**
     * 
     * @type {Array<PaymentInformationDto>}
     * @memberof MemberDto
     */
    extraPaymentInformation?: Array<PaymentInformationDto>;
}

export function MemberDtoFromJSON(json: any): MemberDto {
    return MemberDtoFromJSONTyped(json, false);
}

export function MemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memberId': json['memberId'],
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'identity': CordaX500NameDtoFromJSON(json['identity']),
        'paymentInformation': PaymentInformationDtoFromJSON(json['paymentInformation']),
        'extraPaymentInformation': !exists(json, 'extraPaymentInformation') ? undefined : ((json['extraPaymentInformation'] as Array<any>).map(PaymentInformationDtoFromJSON)),
    };
}

export function MemberDtoToJSON(value?: MemberDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memberId': value.memberId,
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'identity': CordaX500NameDtoToJSON(value.identity),
        'paymentInformation': PaymentInformationDtoToJSON(value.paymentInformation),
        'extraPaymentInformation': value.extraPaymentInformation === undefined ? undefined : ((value.extraPaymentInformation as Array<any>).map(PaymentInformationDtoToJSON)),
    };
}

