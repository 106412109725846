import React from 'react';
import { UseFormikType } from './useFormikType';

// Extend <form> property type with "formik"
type FormikFormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
  formik: UseFormikType;
};

/**
 * Wrapper of <form> to set onReset and onSubmit from formik. Equal to https://formik.org/docs/api/form
 * @param props default <form> properties extended with "formik" parameter
 */
export default function FormikForm(props: FormikFormProps): JSX.Element {
  const { formik, ...other } = props;
  return <form onReset={formik.handleReset} onSubmit={formik.handleSubmit} {...other} autoComplete="off" />;
}
