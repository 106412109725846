/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CordaX500NameDto
 */
export interface CordaX500NameDto {
    /**
     * The name of the node organization.
     * @type {string}
     * @memberof CordaX500NameDto
     */
    organization: string;
    /**
     * The location of the node organization.
     * @type {string}
     * @memberof CordaX500NameDto
     */
    locality: string;
    /**
     * The country of the node organization.
     * @type {string}
     * @memberof CordaX500NameDto
     */
    country: string;
}

export function CordaX500NameDtoFromJSON(json: any): CordaX500NameDto {
    return CordaX500NameDtoFromJSONTyped(json, false);
}

export function CordaX500NameDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CordaX500NameDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization': json['organization'],
        'locality': json['locality'],
        'country': json['country'],
    };
}

export function CordaX500NameDtoToJSON(value?: CordaX500NameDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization': value.organization,
        'locality': value.locality,
        'country': value.country,
    };
}

