/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CordaX500NameDto,
    CordaX500NameDtoFromJSON,
    CordaX500NameDtoFromJSONTyped,
    CordaX500NameDtoToJSON,
} from './CordaX500NameDto';
import {
    InsuranceCreateRequest,
    InsuranceCreateRequestFromJSON,
    InsuranceCreateRequestFromJSONTyped,
    InsuranceCreateRequestToJSON,
} from './InsuranceCreateRequest';
import {
    InsuranceDetailsDto,
    InsuranceDetailsDtoFromJSON,
    InsuranceDetailsDtoFromJSONTyped,
    InsuranceDetailsDtoToJSON,
} from './InsuranceDetailsDto';
import {
    InsuranceDtoAllOf,
    InsuranceDtoAllOfFromJSON,
    InsuranceDtoAllOfFromJSONTyped,
    InsuranceDtoAllOfToJSON,
} from './InsuranceDtoAllOf';
import {
    InsuranceMachineDetailsDto,
    InsuranceMachineDetailsDtoFromJSON,
    InsuranceMachineDetailsDtoFromJSONTyped,
    InsuranceMachineDetailsDtoToJSON,
} from './InsuranceMachineDetailsDto';
import {
    InsurancePayPerUseDetailsDto,
    InsurancePayPerUseDetailsDtoFromJSON,
    InsurancePayPerUseDetailsDtoFromJSONTyped,
    InsurancePayPerUseDetailsDtoToJSON,
} from './InsurancePayPerUseDetailsDto';
import {
    InsurancePaymentDetailsDto,
    InsurancePaymentDetailsDtoFromJSON,
    InsurancePaymentDetailsDtoFromJSONTyped,
    InsurancePaymentDetailsDtoToJSON,
} from './InsurancePaymentDetailsDto';
import {
    InsuranceStatusEnum,
    InsuranceStatusEnumFromJSON,
    InsuranceStatusEnumFromJSONTyped,
    InsuranceStatusEnumToJSON,
} from './InsuranceStatusEnum';

/**
 * 
 * @export
 * @interface InsuranceDto
 */
export interface InsuranceDto {
    /**
     * 
     * @type {Date}
     * @memberof InsuranceDto
     */
    created: Date;
    /**
     * 
     * @type {Date}
     * @memberof InsuranceDto
     */
    updated: Date;
    /**
     * 
     * @type {string}
     * @memberof InsuranceDto
     */
    machineName: string;
    /**
     * 
     * @type {CordaX500NameDto}
     * @memberof InsuranceDto
     */
    insurer: CordaX500NameDto;
    /**
     * 
     * @type {string}
     * @memberof InsuranceDto
     */
    insuranceExternalId: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceDto
     */
    machineId: string;
    /**
     * 
     * @type {CordaX500NameDto}
     * @memberof InsuranceDto
     */
    counterparty: CordaX500NameDto;
    /**
     * 
     * @type {InsuranceStatusEnum}
     * @memberof InsuranceDto
     */
    insuranceStatus: InsuranceStatusEnum;
    /**
     * 
     * @type {InsuranceDetailsDto}
     * @memberof InsuranceDto
     */
    insuranceDetails: InsuranceDetailsDto;
    /**
     * 
     * @type {InsuranceMachineDetailsDto}
     * @memberof InsuranceDto
     */
    machineDetails: InsuranceMachineDetailsDto;
    /**
     * 
     * @type {InsurancePaymentDetailsDto}
     * @memberof InsuranceDto
     */
    paymentDetails: InsurancePaymentDetailsDto;
    /**
     * 
     * @type {InsurancePayPerUseDetailsDto}
     * @memberof InsuranceDto
     */
    payPerUseDetails: InsurancePayPerUseDetailsDto;
}

export function InsuranceDtoFromJSON(json: any): InsuranceDto {
    return InsuranceDtoFromJSONTyped(json, false);
}

export function InsuranceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsuranceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': (new Date(json['created'])),
        'updated': (new Date(json['updated'])),
        'machineName': json['machineName'],
        'insurer': CordaX500NameDtoFromJSON(json['insurer']),
        'insuranceExternalId': json['insuranceExternalId'],
        'machineId': json['machineId'],
        'counterparty': CordaX500NameDtoFromJSON(json['counterparty']),
        'insuranceStatus': InsuranceStatusEnumFromJSON(json['insuranceStatus']),
        'insuranceDetails': InsuranceDetailsDtoFromJSON(json['insuranceDetails']),
        'machineDetails': InsuranceMachineDetailsDtoFromJSON(json['machineDetails']),
        'paymentDetails': InsurancePaymentDetailsDtoFromJSON(json['paymentDetails']),
        'payPerUseDetails': InsurancePayPerUseDetailsDtoFromJSON(json['payPerUseDetails']),
    };
}

export function InsuranceDtoToJSON(value?: InsuranceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': (value.created.toISOString()),
        'updated': (value.updated.toISOString()),
        'machineName': value.machineName,
        'insurer': CordaX500NameDtoToJSON(value.insurer),
        'insuranceExternalId': value.insuranceExternalId,
        'machineId': value.machineId,
        'counterparty': CordaX500NameDtoToJSON(value.counterparty),
        'insuranceStatus': InsuranceStatusEnumToJSON(value.insuranceStatus),
        'insuranceDetails': InsuranceDetailsDtoToJSON(value.insuranceDetails),
        'machineDetails': InsuranceMachineDetailsDtoToJSON(value.machineDetails),
        'paymentDetails': InsurancePaymentDetailsDtoToJSON(value.paymentDetails),
        'payPerUseDetails': InsurancePayPerUseDetailsDtoToJSON(value.payPerUseDetails),
    };
}

