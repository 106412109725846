import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid, LinearProgress, MenuItem } from '@material-ui/core';

import FormikFormControlCheckbox, { CheckboxesData } from '../../assets/formik/FormikFormControlCheckbox';
import FormikTextField from '../../assets/formik/FormikTextField';
import useNotifications from '../../assets/useNotifications';
import FormikForm from '../../assets/formik/FormikForm';
import {
  MachineDto,
  MachineRequest,
  MemberDto,
  MembershipDto,
  MembershipRoleEnum,
  PaymentConfigurationDTO,
  PaymentConfigurationRequestPaymentConfigurationTypeEnum,
} from '../../../generated';
import {
  getMachinesApi,
  getMemberApi,
  getMembershipsApi,
  getNetworkApi,
  getPaymentConfigurationApi,
} from '../../../common/keycloak';
import {
  formatCordaX500Name,
  stringToCordaX500Name,
  formatPaymentInformation,
  stringToPaymentInformation,
} from '../../../common/format';
import FormikFormControlSelect from '../../assets/formik/FormikFormControlSelect';
import { NumberFormatEurInput } from '../../assets/numberformat/NumberFormatEur';
import { useStyles } from '../../Styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: unknown;
  value: unknown;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ height: '391.8px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: unknown) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/**
 * JSX Element that is an overlay dialog to edit a machine
 * @param props
 */
export default function MachineDialog(props: {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  machineDto?: MachineDto;
}): JSX.Element {
  const { promiseInProgress } = usePromiseTracker({ area: 'machine-dialog', delay: 200 });
  const { showError, showSuccess } = useNotifications();

  const [memberships, setMemberships] = useState({} as MembershipsPerRole);
  const [members, setMembers] = useState({} as MemberDto);
  const [paymentConfigState, setPaymentConfigState] = useState({} as PaymentConfigurationDTO);

  const timeBasedOptions = [1, 5, 10, 15, 20, 25];

  // Trnaslations
  const { t } = useTranslation();
  const noneTxt = t('none');
  const saveBtnTxt = t('saveBtn');
  const closeBtnTxt = t('closeBtn');
  const insurersTxt = t('insurers');
  const machineUserTxt = t('machineUser');
  const machineNameTxt = t('machineName');
  const machineOwnerTxt = t('machineOwner');
  const bankAccountsTxt = t('bankAccounts');
  const pricePerUnitTxt = t('pricePerUnit');
  const paymentMethodTxt = t('paymentMethod');
  const tresholdTriggerTxt = t('tresholdTrigger');
  const paymentProviderTxt = t('paymentProvider');
  const machineObserversTxt = t('machineObservers');
  const paymentThresholdTxt = t('paymentThreshold');
  const timeBasedTriggerTxt = t('timeBasedTrigger');
  const addMachineTxt = t('machineDialog.addMachine');
  const endOfMonthTxt = t('machineDialog.endOfMonth');
  const editMachineTxt = t('machineDialog.editMachine');
  const extraSettingsTxt = t('machineDialog.extraSettings');
  const selectOnlyTwoDaysTxt = t('machineDialog.selectOnlyTwoDays');
  const ppcPositiveNumberTxt = t('machineDialog.ppcPositiveNumber');
  const oneDayNeedSelectedTxt = t('machineDialog.oneDayNeedSelected');
  const machineNameRequiredTxt = t('machineDialog.machineNameRequired');
  const daysOfMonthRequiredTxt = t('machineDialog.daysOfMonthRequired');
  const nomoreTwoDaysSelectedTxt = t('machineDialog.nomoreTwoDaysSelected');
  const thresholdPositiveNumberTxt = t('machineDialog.thresholdPositiveNumber');
  const machineNameExceedCharTxt = t('exceedChar', { field: t('fields.machineName') });

  const postPaymentConfig = async (
    paymentConfigType: PaymentConfigurationRequestPaymentConfigurationTypeEnum,
    machineId: string,
    daysOfMonth?: number[] | undefined
  ) => {
    const paymentConfigurationApi = await getPaymentConfigurationApi();
    await paymentConfigurationApi.setPaymentConfiguration({
      daysOfMonth: daysOfMonth,
      paymentConfigurationType: paymentConfigType,
      machineId: machineId,
    });
  };

  /**
   * Send updated machine state via API when the save button is pressed
   */
  const handleUpdate = async function (machineDialogTypeData: MachineDialogType) {
    if (!props.machineDto) return;
    const machine = props.machineDto;

    // Update paymentConfiguration
    if (formik.values.thresholdTrigger && formik.values.timeBasedTrigger) {
      const paymentConfigurationType = 'BOTH';
      let daysOfMonth: number[] | undefined = [];

      if (machineDialogTypeData.daysOfMonth) {
        daysOfMonth = machineDialogTypeData.daysOfMonth;
      }
      // if (machineDialogTypeData.daysOfMonth?.includes(31)) {
      //   daysOfMonth.push(31);
      // }
      await trackPromise(
        postPaymentConfig(paymentConfigurationType, props.machineDto.machineId, daysOfMonth),
        'machine-dialog'
      );
    } else if (formik.values.timeBasedTrigger) {
      const paymentConfigurationType = 'DAY_OF_MONTH';
      let daysOfMonth: number[] | undefined = [];
      if (machineDialogTypeData.daysOfMonth) {
        daysOfMonth = machineDialogTypeData.daysOfMonth;
      }
      // if (machineDialogTypeData.daysOfMonth?.includes(31)) {
      //   daysOfMonth.push(31);
      // }
      await trackPromise(
        postPaymentConfig(paymentConfigurationType, props.machineDto.machineId, daysOfMonth),
        'machine-dialog'
      );
    } else if (formik.values.thresholdTrigger) {
      const paymentConfigurationType = 'THRESHOLD';
      await trackPromise(postPaymentConfig(paymentConfigurationType, props.machineDto.machineId), 'machine-dialog');
    }

    // Call MachineApi to send updated member
    const updateMachine = async () => {
      const machinesApi = await getMachinesApi();
      return machinesApi.updateMachine(machine.machineId, mapToMachineRequest(machineDialogTypeData));
    };

    await executeTask(updateMachine, 'update');
  };

  /**
   * Create machine state via API when the save button is pressed
   */
  const handleCreate = async function (machineDialogTypeData: MachineDialogType) {
    const createMachine = async () => {
      const machinesApi = await getMachinesApi();
      return machinesApi.createMachine(mapToMachineRequest(machineDialogTypeData));
    };

    await executeTask(createMachine, 'create');
  };

  /**
   * Executes an async function and processes the output
   * @param fun
   * @param description
   */
  const executeTask = async function (fun: () => Promise<boolean>, action: string) {
    try {
      const result = await trackPromise(fun(), 'machine-dialog');

      if (result) {
        showSuccess(t(`machineDialog.success.${action}`));
        props.onSave();
      } else {
        showError(t(`machineDialog.error.${action}`));
      }
    } catch (e) {
      if (e instanceof Response) {
        showError(
          t(`machineDialog.error.${action}`, { status: e.status, message: await e.text() }) ||
            `Error while ${action}ing machine with status "${e.status}": ${await e.text()}`
        );
      } else {
        showError('' + e);
      }
    }
  };

  /**
   * Interface to store the current state of the form used by formik
   */
  interface MachineDialogType {
    machineOwner: string;
    machineUser: string;
    machineName: string;
    pricePerUnit: number;
    paymentThreshold: number;
    paymentProvider: string;
    paymentInformation?: string;
    insurers: string[];
    machineObservers: string[];
    thresholdTrigger: boolean;
    timeBasedTrigger: boolean;
    daysOfMonth?: number[];
  }

  const mapToMachineRequest = function (machineDialogTypeData: MachineDialogType): MachineRequest {
    return {
      machineOwner: stringToCordaX500Name(machineDialogTypeData.machineOwner),
      machineUser: stringToCordaX500Name(machineDialogTypeData.machineUser),
      machineName: machineDialogTypeData.machineName,
      pricePerUnit: machineDialogTypeData.pricePerUnit,
      paymentThreshold: machineDialogTypeData.paymentThreshold,
      paymentProvider: stringToCordaX500Name(machineDialogTypeData.paymentProvider),
      paymentInformation: stringToPaymentInformation(machineDialogTypeData.paymentInformation),
      insurers: machineDialogTypeData.insurers.map(stringToCordaX500Name),
      machineObservers: machineDialogTypeData.machineObservers.map(stringToCordaX500Name),
    };
  };

  const mapToMachineDialogType = function (
    machine: MachineDto,
    paymentConfig: PaymentConfigurationDTO
  ): MachineDialogType {
    const timeBasedMod =
      paymentConfig.paymentConfigurationType === 'DAY_OF_MONTH' || paymentConfig.paymentConfigurationType === 'BOTH';

    const thresholdMod =
      paymentConfig.paymentConfigurationType === 'THRESHOLD' || paymentConfig.paymentConfigurationType === 'BOTH';

    return {
      machineOwner: formatCordaX500Name(machine.machineOwner),
      machineUser: formatCordaX500Name(machine.machineUser),
      machineName: machine.machineName,
      pricePerUnit: machine.pricePerUnit,
      paymentThreshold: machine.paymentThreshold ?? 100000,
      paymentProvider: formatCordaX500Name(machine.paymentProvider),
      paymentInformation: formatPaymentInformation(machine.paymentInformation),
      insurers: machine.insurers.map(formatCordaX500Name),
      machineObservers: machine.machineObservers.map(formatCordaX500Name),
      thresholdTrigger: thresholdMod,
      timeBasedTrigger: timeBasedMod,
      daysOfMonth: paymentConfig.daysOfMonth ?? [],
    };
  };

  interface MembershipsPerRole {
    bno: MembershipDto[];
    machineOwners: MembershipDto[];
    machineUsers: MembershipDto[];
    paymentProviders: MembershipDto[];
    insurers: MembershipDto[];
    machineObservers: MembershipDto[];
  }

  /**
   * Converts a membership array to a MembershipsPerRole object
   * @param memberships
   */
  const convertToMembershipsPerRole = function (memberships: MembershipDto[]): MembershipsPerRole {
    const out = {
      bno: [] as MembershipDto[],
      machineOwners: [] as MembershipDto[],
      machineUsers: [] as MembershipDto[],
      paymentProviders: [] as MembershipDto[],
      insurers: [] as MembershipDto[],
      machineObservers: [] as MembershipDto[],
    };

    memberships.forEach((membership: MembershipDto) => {
      membership.membershipRoles.forEach((role: MembershipRoleEnum) => {
        switch (role) {
          case MembershipRoleEnum.Bno:
            out.bno.push(membership);
            break;
          case MembershipRoleEnum.MachineOwner:
            out.machineOwners.push(membership);
            break;
          case MembershipRoleEnum.MachineUser:
            out.machineUsers.push(membership);
            break;
          case MembershipRoleEnum.PaymentProvider:
            out.paymentProviders.push(membership);
            break;
          case MembershipRoleEnum.Insurer:
            out.insurers.push(membership);
            break;
          case MembershipRoleEnum.MachineObserver:
            out.machineObservers.push(membership);
            break;
        }
      });
    });

    return out;
  };

  let validationSchema = yup.object({
    machineName: yup.string().required(machineNameRequiredTxt).max(128, machineNameExceedCharTxt),
    pricePerUnit: yup.number().required().positive(ppcPositiveNumberTxt),
    paymentThreshold: yup.number().required().positive(thresholdPositiveNumberTxt),
    daysOfMonth: yup.array().of(yup.number().required(oneDayNeedSelectedTxt)).max(2, nomoreTwoDaysSelectedTxt),
  });

  const formik = useFormik({
    initialValues: {
      machineOwner: '',
      machineUser: '',
      machineName: '',
      pricePerUnit: 0.0,
      paymentThreshold: 0.0,
      paymentProvider: '',
      paymentInformation: '',
      insurers: [],
      machineObservers: [],
      thresholdTrigger: false,
      timeBasedTrigger: false,
      daysOfMonth: [],
    } as MachineDialogType,
    validationSchema: validationSchema,
    onSubmit: props.machineDto ? handleUpdate : handleCreate,
  });

  if (formik.values.timeBasedTrigger) {
    validationSchema = validationSchema.shape({
      daysOfMonth: yup
        .array()
        .of(yup.number().required(oneDayNeedSelectedTxt))
        .min(1, oneDayNeedSelectedTxt)
        .max(2, nomoreTwoDaysSelectedTxt)
        .required(daysOfMonthRequiredTxt),
    });
  }
  // Initialize form
  useEffect(() => {
    if (!props.open) return;
    formik.resetForm();

    const initialize = async function () {
      // Load memberships
      const membershipsApi = await getMembershipsApi();
      const membershipDtos = await membershipsApi.getMemberships();

      setMemberships(convertToMembershipsPerRole(membershipDtos));

      // Load members
      const memberApi = await getMemberApi();
      const memberDto = await memberApi.getMember();

      setMembers(memberDto);

      // Load paymentConfiguration
      const paymentConfigurationApi = await getPaymentConfigurationApi();
      if (props.machineDto?.machineId) {
        const paymentConfiguration = await paymentConfigurationApi.getPaymentConfiguration(props.machineDto.machineId);
        setPaymentConfigState(paymentConfiguration);
      }
      // Set machineOwner to current Node (new) if there is no props.machineDto
      if (!props.machineDto) {
        const networkApi = await getNetworkApi();
        const currentNode = await networkApi.getActiveNode();
        await formik.setFieldValue('machineOwner', formatCordaX500Name(currentNode.identity));
      }
    };

    trackPromise(initialize(), 'machine-dialog');

    // Adding 'formik' to dependencies creates an infinite loop as formik changes every render
  }, [props.open, props.machineDto]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const updateFormikValues = async () => {
      // Set values of props.machineDto if exists (edit)
      if (props.machineDto) {
        await formik.setValues(mapToMachineDialogType(props.machineDto, paymentConfigState));
        await formik.validateForm();
      }
    };

    updateFormikValues();
  }, [paymentConfigState, props.machineDto]); // eslint-disable-line react-hooks/exhaustive-deps

  // tab setting **********************************************************
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  // Defining the checkboxes
  const roleCheckboxes: CheckboxesData[] = [
    {
      id: 'thresholdTrigger',
      label: tresholdTriggerTxt,
    },
    {
      id: 'timeBasedTrigger',
      label: timeBasedTriggerTxt,
    },
  ];
  // tab setting **********************************************************

  return (
    <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
      {/* Without div the progress bar is not visible when scrollbar is shown */}
      {promiseInProgress && (
        <div>
          <LinearProgress />
        </div>
      )}

      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" className={classes.tabsContainer}>
        <Tab label={props.machineDto ? editMachineTxt : addMachineTxt} {...a11yProps(0)} />
        <Tab label={extraSettingsTxt} {...a11yProps(1)} />
      </Tabs>

      <FormikForm formik={formik}>
        <DialogContent>
          {/* {props.machineDto?.machineId && (
            <DialogContentText style={{ marginBottom: 20, marginTop: -20 }}></DialogContentText>
          )} */}
          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormikFormControlSelect
                  formik={formik}
                  label={machineOwnerTxt}
                  id="machineOwner"
                  variant="outlined"
                  fullWidth
                  required
                  disabled
                >
                  <MenuItem value="">
                    <em>{noneTxt}</em>
                  </MenuItem>
                  {memberships.machineOwners?.map((membership, index) => (
                    <MenuItem key={index} value={formatCordaX500Name(membership.identity)}>
                      {formatCordaX500Name(membership.identity)}
                    </MenuItem>
                  ))}
                </FormikFormControlSelect>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikFormControlSelect
                  formik={formik}
                  label={machineUserTxt}
                  id="machineUser"
                  variant="outlined"
                  fullWidth
                  required
                  disabled={promiseInProgress || !!props.machineDto}
                >
                  <MenuItem value="">
                    <em>{noneTxt}</em>
                  </MenuItem>
                  {memberships.machineUsers?.map((membership, index) => (
                    <MenuItem key={index} value={formatCordaX500Name(membership.identity)}>
                      {formatCordaX500Name(membership.identity)}
                    </MenuItem>
                  ))}
                </FormikFormControlSelect>
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  formik={formik}
                  id="machineName"
                  variant="outlined"
                  fullWidth
                  label={machineNameTxt}
                  required
                  disabled={promiseInProgress}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikTextField
                  formik={formik}
                  id="pricePerUnit"
                  variant="outlined"
                  fullWidth
                  label={pricePerUnitTxt}
                  InputProps={{
                    inputComponent: NumberFormatEurInput as never,
                  }}
                  required
                  disabled={promiseInProgress}
                />
              </Grid>
              {props.machineDto && (
                <Grid item xs={12} sm={6} style={{ padding: '0 0 0 8px' }}>
                  <FormikFormControlCheckbox
                    className={classes.pmFormLabel}
                    formik={formik}
                    variant="outlined"
                    checkboxData={roleCheckboxes}
                    label={paymentMethodTxt}
                    fullWidth
                    required
                    disabled={promiseInProgress}
                  />
                </Grid>
              )}
              {(!props.machineDto || formik.values.thresholdTrigger) && (
                <Grid item xs={12} sm={!props.machineDto ? 6 : undefined}>
                  <FormikTextField
                    formik={formik}
                    id="paymentThreshold"
                    variant="outlined"
                    fullWidth
                    label={paymentThresholdTxt}
                    InputProps={{
                      inputComponent: NumberFormatEurInput as never,
                    }}
                    required
                    disabled={promiseInProgress}
                  />
                </Grid>
              )}
              {formik.values.timeBasedTrigger && (
                <Grid item xs={12}>
                  <FormikFormControlSelect
                    formik={formik}
                    label={timeBasedTriggerTxt}
                    id="daysOfMonth"
                    variant="outlined"
                    fullWidth
                    required
                    disabled={promiseInProgress}
                    multiple
                    renderValue={(selected: unknown) => {
                      const selectedValues = selected as number[];
                      return selectedValues.map((val) => (val === 31 ? 31 : val)).join(', ');
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>{selectOnlyTwoDaysTxt}</em>
                    </MenuItem>
                    {timeBasedOptions.map((tb, index) => {
                      return (
                        <MenuItem key={index} value={tb}>
                          {tb}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value={31}>
                      <em>{endOfMonthTxt}</em>
                    </MenuItem>
                  </FormikFormControlSelect>
                  {formik.errors.daysOfMonth && formik.touched.daysOfMonth ? (
                    <div style={{ color: 'red' }}>{formik.errors.daysOfMonth}</div>
                  ) : null}
                </Grid>
              )}

              <Grid item xs={12}>
                <FormikFormControlSelect
                  formik={formik}
                  label={paymentProviderTxt}
                  id="paymentProvider"
                  variant="outlined"
                  fullWidth
                  required
                  disabled={promiseInProgress}
                >
                  <MenuItem value="">
                    <em>{noneTxt}</em>
                  </MenuItem>
                  {memberships.paymentProviders?.map((membership, index) => {
                    return (
                      <MenuItem key={index} value={formatCordaX500Name(membership.identity)}>
                        {formatCordaX500Name(membership.identity)}
                      </MenuItem>
                    );
                  })}
                </FormikFormControlSelect>
              </Grid>
              <Grid item xs={12}>
                <FormikFormControlSelect
                  formik={formik}
                  label={bankAccountsTxt}
                  id="paymentInformation"
                  variant="outlined"
                  fullWidth
                  disabled={promiseInProgress}
                >
                  {members.paymentInformation && (
                    <MenuItem key="paymentInfo" value={formatPaymentInformation(members.paymentInformation)}>
                      {`${members.paymentInformation.displayName} (${members.paymentInformation.iban})`}
                    </MenuItem>
                  )}
                  {members.extraPaymentInformation &&
                    members.extraPaymentInformation.length > 0 &&
                    members.extraPaymentInformation.map((paymentInfo, index) => {
                      return (
                        <MenuItem key={index} value={formatPaymentInformation(paymentInfo)}>
                          {`${paymentInfo.displayName} (${paymentInfo.iban})`}
                        </MenuItem>
                      );
                    })}
                </FormikFormControlSelect>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.gridItem}>
                <FormikFormControlSelect
                  formik={formik}
                  label={insurersTxt}
                  id="insurers"
                  variant="outlined"
                  fullWidth
                  multiple
                  disabled={promiseInProgress}
                >
                  {memberships.insurers?.map((membership, index) => (
                    <MenuItem key={index} value={formatCordaX500Name(membership.identity)}>
                      {formatCordaX500Name(membership.identity)}
                    </MenuItem>
                  ))}
                </FormikFormControlSelect>
              </Grid>
              <Grid item xs={12} className={classes.gridItem}>
                <FormikFormControlSelect
                  formik={formik}
                  label={machineObserversTxt}
                  id="machineObservers"
                  variant="outlined"
                  fullWidth
                  multiple
                  disabled={promiseInProgress}
                >
                  {memberships.machineObservers?.map((membership, index) => (
                    <MenuItem key={index} value={formatCordaX500Name(membership.identity)}>
                      {formatCordaX500Name(membership.identity)}
                    </MenuItem>
                  ))}
                </FormikFormControlSelect>
              </Grid>
            </Grid>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.onClose}>
            {closeBtnTxt}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              promiseInProgress ||
              !formik.isValid ||
              (props.machineDto && !formik.values.timeBasedTrigger && !formik.values.thresholdTrigger)
            }
          >
            {saveBtnTxt}
          </Button>
        </DialogActions>
      </FormikForm>
    </Dialog>
  );
}
