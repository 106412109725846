import React from 'react';
import get from 'lodash/get';
import { UseFormikType } from './useFormikType';
import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers';

// Extend <KeyboardDateTimePickerProps> property type with "formik"
type FormikKeyboardDateTimePickerProps = Omit<Omit<KeyboardDateTimePickerProps, 'onChange'>, 'value'> & {
  formik: UseFormikType;
  id: string;
};

/**
 * Wrapper of <KeyboardDateTimePicker> to set value, onChange, error, helperText based on values managed by formik.
 * @param props default <KeyboardDateTimePicker> properties extended with "formik" parameter
 */
export default function FormikKeyboardDateTimePicker(props: FormikKeyboardDateTimePickerProps): JSX.Element {
  const { formik, id, format, ampm, ...other } = props;

  if (!id) throw new Error('FormikKeyboardDateTimePicker "id" property must not be empty');

  return (
    <KeyboardDateTimePicker
      id={id}
      value={get(formik.values, id, null)}
      onChange={(date) => formik.setFieldValue(id, date)}
      error={get(formik.touched, id, false) && Boolean(get(formik.errors, id))}
      onError={(error) => {
        if (error != null && error !== get(formik.errors, id)) {
          formik.setFieldError(id, error.toString());
        }
      }}
      helperText={get(formik.touched, id, false) && get(formik.errors, id)}
      format={format ?? 'dd/MM/yyyy, HH:mm'}
      ampm={ampm ?? false}
      {...other}
    />
  );
}
