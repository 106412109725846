/* tslint:disable */
/* eslint-disable */
/**
 * PayperChain API
 * The MVP uses distributed ledger technology (DLT) to track the usage of the molding machine and automatically triggers the payments. 
 *
 * The version of the OpenAPI document: MVP
 * Contact: info@payperchain.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NodeDto,
    NodeDtoFromJSON,
    NodeDtoFromJSONTyped,
    NodeDtoToJSON,
} from './NodeDto';

/**
 * 
 * @export
 * @interface NetworkNodesDto
 */
export interface NetworkNodesDto {
    /**
     * 
     * @type {NodeDto}
     * @memberof NetworkNodesDto
     */
    activeNode: NodeDto;
    /**
     * The other nodes in the network.
     * @type {Array<NodeDto>}
     * @memberof NetworkNodesDto
     */
    otherNodes?: Array<NodeDto>;
    /**
     * A list of all notaries in the network.
     * @type {Array<NodeDto>}
     * @memberof NetworkNodesDto
     */
    notaries?: Array<NodeDto>;
}

export function NetworkNodesDtoFromJSON(json: any): NetworkNodesDto {
    return NetworkNodesDtoFromJSONTyped(json, false);
}

export function NetworkNodesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkNodesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeNode': NodeDtoFromJSON(json['activeNode']),
        'otherNodes': !exists(json, 'otherNodes') ? undefined : ((json['otherNodes'] as Array<any>).map(NodeDtoFromJSON)),
        'notaries': !exists(json, 'notaries') ? undefined : ((json['notaries'] as Array<any>).map(NodeDtoFromJSON)),
    };
}

export function NetworkNodesDtoToJSON(value?: NetworkNodesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeNode': NodeDtoToJSON(value.activeNode),
        'otherNodes': value.otherNodes === undefined ? undefined : ((value.otherNodes as Array<any>).map(NodeDtoToJSON)),
        'notaries': value.notaries === undefined ? undefined : ((value.notaries as Array<any>).map(NodeDtoToJSON)),
    };
}

